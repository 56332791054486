import { Grid2 } from '@mui/material'
import AppAutocompleteFormField from '../../components/AppFormField/AppAutocompleteFormField'
import { useTranslation } from 'react-i18next'
import { IAddSubActivitySectionFormProps } from './validation'

export default function Activity(
  {
    values,
    errors,
    touched,
    handleChange,
    setFieldValue,
    disabled,
    handleBlur,
    activities,
  }: IAddSubActivitySectionFormProps
) {
  const { t } = useTranslation("translation", { keyPrefix: "subActivity" })
  const options = activities.map((activity) => ({
    id: activity.id,
    label: activity.activity_name,
  }))
  console.log("options", options)
  const handleAssigneeChange = (
    field: string,
    value: any,
    shouldValidate?: boolean,) => {
    setFieldValue(field, value, shouldValidate)
  }

  return (
    <Grid2 display={"flex"} justifyContent={"space-between"} gap={2}>
      <Grid2 width={"100%"}>
        <AppAutocompleteFormField
          label={t("activity")}
          isRequired
          // showLoader={isFetchingPrjMembers}
          name={"activity"}
          placeholder={t("activity")}
          // value={(activities.length>0 ? values?.activity : '' )|| ''}
          value={values.activity?.toString() || values?.toString() || values}
          disabled={disabled}
          options={options}
          // multiple={true}
          onChange={disabled ? undefined : handleAssigneeChange}
          onBlur={handleBlur}
          touched={touched.activity}
          errorMsg={errors.activity ? t(errors.activity) : ""}
        />
      </Grid2>
    </Grid2>
  )
}
