import React, { useEffect, useState } from "react";
import { AppBar, Grid2, Toolbar, Typography } from "@mui/material";
import "./AppNavBar.scss";
export default function AppNavBar(props: {
    options: string[],
    handleClickLabel: (label: string) => void,
    defaultSelected: string,
    marginBottom: boolean
}) {
    const { options, handleClickLabel, defaultSelected, marginBottom } = props;
    const [selectedOption, setSelectedOption] = useState<number | null>(null);

    const handleOptionsClick = (index: number) => {
        setSelectedOption(index);
        handleClickLabel(options[index]);
        console.log("Options Clicked", options[index]);
    };

    useEffect(() => {
        const index = options.findIndex((option) => option === defaultSelected);
        setSelectedOption(index);
    }, [defaultSelected]);

    return (
        <AppBar position="static" style={{ borderRadius: '10px' }} >
            <Toolbar variant="dense" >
                <Grid2 container marginLeft={-1} marginBottom={marginBottom ? '-10px' : ''} spacing={2} display={'flex'}
                    justifyContent={'space-between'}>
                    {options.map((option, index) => (
                        <Typography
                            key={index}
                            // variant="h3"
                            color="inherit"
                            component="div"
                            className="title2"
                            onClick={() => handleOptionsClick(index)}
                            style={{
                                backgroundColor: selectedOption === index ? 'white' : 'transparent',
                                color: selectedOption === index ? 'black' : 'inherit',
                                padding: '5px',
                                borderRadius: '4px',
                                cursor: 'pointer',
                                fontWeight: 'bold'
                            }}
                        >
                            {option}
                        </Typography>
                    ))}
                </Grid2>
            </Toolbar>
        </AppBar>
    );
}
