import { localStorage } from "./../../utils/localstorage"
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { API_ENDPOINTS, API_SERVER_URL } from "./endpoints"

import { API_METHODS } from "../../constants/constants"
import { handleAuthError } from "./errorHandler"
import {
	GetUsersListPayload,
	InviteuserPayload,
	LogoutResponse,
	TUserResponse,
	UserStatusChangePayload,
} from "../types/User"
import { ListingResponse } from "../types/Listing"
import {
	ProfileDataResponseSuccess,
	ProfilePayload,
	ProfileSetUpResponse,
	TUserRole,
} from "../types/Auth"

export const UserAPIs = createApi({
	reducerPath: "userAPI",
	baseQuery: fetchBaseQuery({
		baseUrl: API_SERVER_URL,
		prepareHeaders: async (headers) => {
			const data = await localStorage.getUserData()
			headers.set("Content-Type", "application/json")
			headers.set("Authorization", `Token ${data?.token}`)
			return headers
		},
	}),
	endpoints: (builder) => ({
		changeUserStatus: builder.query<any, UserStatusChangePayload>({
			query: (params: UserStatusChangePayload) => {
				return {
					url: API_ENDPOINTS.USERS.CHANGE_STATUS,
					method: API_METHODS.GET,
					params: params,
				}
			},
			transformErrorResponse(baseQueryReturnValue) {
				handleAuthError(baseQueryReturnValue)
				return baseQueryReturnValue
			},
			transformResponse: async (data: any) => {
				return data
			},
		}),
		addUser: builder.mutation<any, InviteuserPayload>({
			query: (params: InviteuserPayload) => {
				return {
					url: API_ENDPOINTS.USERS.INVITE,
					method: API_METHODS.POST,
					body: params,
				}
			},
			transformErrorResponse(baseQueryReturnValue) {
				handleAuthError(baseQueryReturnValue)
				return baseQueryReturnValue
			},
			transformResponse: async (data: any) => {
				return data
			},
		}),
		getAllUsers: builder.query<
			ListingResponse<TUserResponse[]>,
			GetUsersListPayload
		>({
			query: (params: GetUsersListPayload) => {
				return {
					url: API_ENDPOINTS.USERS.USERS_ACTIVATION,
					method: API_METHODS.GET,
					params,
				}
			},
			transformErrorResponse(baseQueryReturnValue) {
				handleAuthError(baseQueryReturnValue)
				return baseQueryReturnValue
			},
			transformResponse: async (data: ListingResponse<TUserResponse[]>) => {
				return data
			},
		}),
		getLimitedRoles: builder.query<TUserRole[], void>({
			query: () => {
				return {
					url: API_ENDPOINTS.USERS.LIMITED_ROLES,
					method: API_METHODS.GET,
				}
			},
			transformErrorResponse(baseQueryReturnValue) {
				handleAuthError(baseQueryReturnValue)
				return baseQueryReturnValue
			},
			transformResponse: async (data: TUserRole[]) => {
				return data
			},
		}),
		passwordLogs: builder.query<
			any,
			{ id: string; page: number; page_size: number }
		>({
			query: ({ id, page, page_size }) => {
				return {
					url: `${API_ENDPOINTS.PASSWORD_RESET_LOGS}?user_id=${id}&page=${page}&page_size=${page_size}`, // Append the id to the endpoint
					method: API_METHODS.GET,
				}
			},
			transformErrorResponse(baseQueryReturnValue) {
				handleAuthError(baseQueryReturnValue)
				return baseQueryReturnValue
			},
			transformResponse: async (data: any) => {
				return data
			},
		}),
		updateProfileLogs: builder.query<
			any,
			{ id: string; page: number; page_size: number }
		>({
			query: ({ id, page, page_size }) => {
				return {
					url: `${API_ENDPOINTS.USER_PROFILE_UPDATE_LOGS}?user_id=${id}&page=${page}&page_size=${page_size}`, // Append the id to the endpoint
					method: API_METHODS.GET,
				}
			},
			transformErrorResponse(baseQueryReturnValue) {
				handleAuthError(baseQueryReturnValue)
				return baseQueryReturnValue
			},
			transformResponse: async (data: any) => {
				return data
			},
		}),
		userLoginAttemptsLogs: builder.query<
			any,
			{ id: string; page: number; page_size: number }
		>({
			query: ({ id, page, page_size }) => {
				return {
					url: `${API_ENDPOINTS.LOGIN_ATTEMPTS_LOGS}?user_id=${id}&page=${page}&page_size=${page_size}`, // Append the id to the endpoint
					method: API_METHODS.GET,
				}
			},
			transformErrorResponse(baseQueryReturnValue) {
				handleAuthError(baseQueryReturnValue)
				return baseQueryReturnValue
			},
			transformResponse: async (data: any) => {
				return data
			},
		}),
		activationDeactivationLogs: builder.query<
			any,
			{ id: string; page: number; page_size: number }
		>({
			query: ({ id, page, page_size }) => {
				return {
					url: `${API_ENDPOINTS.ACTIVATION_DEACTIVATION_LOGS}?user_id=${id}&page=${page}&page_size=${page_size}`, // Append the id to the endpoint
					method: API_METHODS.GET,
				}
			},
			transformErrorResponse(baseQueryReturnValue) {
				handleAuthError(baseQueryReturnValue)
				return baseQueryReturnValue
			},
			transformResponse: async (data: any) => {
				return data
			},
		}),
		invitationLogs: builder.query<
			any,
			{ id: string; page: number; page_size: number; search: string }>({
				query: ({ id, page, page_size, search }) => {
					return {
						url: `${API_ENDPOINTS.INVITATION_LOGS}?user_id=${id}&page=${page}&page_size=${page_size}&search=${search}`,
						method: API_METHODS.GET,
					}
				},
				transformErrorResponse(baseQueryReturnValue) {
					handleAuthError(baseQueryReturnValue)
					return baseQueryReturnValue
				},
				transformResponse: async (data: any) => {
					return data
				},
			}),
		profileSetup: builder.mutation<ProfileSetUpResponse, ProfilePayload>({
			query: (params: ProfilePayload) => {
				return {
					url: API_ENDPOINTS.COMPANY.PROFILE_SETUP,
					method: API_METHODS.PATCH,
					body: params,
				}
			},
			transformErrorResponse(baseQueryReturnValue) {
				handleAuthError(baseQueryReturnValue)
				return baseQueryReturnValue
			},
			transformResponse: async (data: ProfileSetUpResponse) => {
				return data
			},
		}),
		updateOtherProfiles: builder.mutation<ProfileSetUpResponse, { id: string; payload: ProfilePayload }>({
			query: ({ id, payload }) => {
				return {
					url: API_ENDPOINTS.COMPANY.UPDATE_OTHER_PROFILE(id),
					method: API_METHODS.PATCH,
					body: payload,
				}
			},
			transformErrorResponse(baseQueryReturnValue) {
				handleAuthError(baseQueryReturnValue)
				return baseQueryReturnValue
			},
			transformResponse: async (data: ProfileSetUpResponse) => {
				return data
			},
		}),
		getUserProfile: builder.query<ProfileDataResponseSuccess, string | void>({
			query: (id) => {
				return {
					url: id
						? API_ENDPOINTS.USERS.GET_PUBLIC_PROFILE(id)
						: API_ENDPOINTS.COMPANY.GET_PROFILE,
					method: API_METHODS.GET,
				}
			},
			transformErrorResponse(baseQueryReturnValue) {
				handleAuthError(baseQueryReturnValue)
				return baseQueryReturnValue
			},
			transformResponse: async (data: ProfileDataResponseSuccess) => {
				return data
			},
		}),
		logout: builder.mutation<LogoutResponse, null>({
			query: () => {
				return {
					url: API_ENDPOINTS.LOGOUT,
					method: API_METHODS.POST,
				}
			},
			transformErrorResponse(baseQueryReturnValue) {
				handleAuthError(baseQueryReturnValue)
				return baseQueryReturnValue
			},
			transformResponse: async (data: LogoutResponse) => {
				return data
			},
		}),
		activateDeactivateUser: builder.mutation<any, any>({
			query: (params) => {
				return {
					url: API_ENDPOINTS.ACTIVATE_DEACTIVATE_USER(params.id),
					method: API_METHODS.PATCH,
					body: params,
				}
			},
			transformErrorResponse(baseQueryReturnValue) {
				handleAuthError(baseQueryReturnValue)
				return baseQueryReturnValue
			},
		}),
		getAllInvitedUsers: builder.query<any, any>({
			query: () => {
				return {
					url: API_ENDPOINTS.USERS.INVITE,
					method: API_METHODS.GET,
				}
			},
			transformErrorResponse(baseQueryReturnValue) {
				handleAuthError(baseQueryReturnValue)
				return baseQueryReturnValue
			},
		}),
	}),
})

export const {
	useLazyChangeUserStatusQuery,
	useAddUserMutation,
	useGetAllUsersQuery,
	useGetLimitedRolesQuery,
	useLazyPasswordLogsQuery,
	useLazyUpdateProfileLogsQuery,
	useLazyUserLoginAttemptsLogsQuery,
	useLazyActivationDeactivationLogsQuery,
	useLazyInvitationLogsQuery,
	useProfileSetupMutation,
	useUpdateOtherProfilesMutation,
	useGetUserProfileQuery,
	useLogoutMutation,
	useActivateDeactivateUserMutation,
	useGetAllInvitedUsersQuery,
} = UserAPIs

export const publicUserProfileSelector = (id: string) => {
	return UserAPIs.endpoints.getUserProfile.select(id)
}