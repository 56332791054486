import { Box, Grid2, Typography } from "@mui/material"
import "./UserProfile.scss"
import AppButton from "../../components/AppButton/AppButton"
import { useTranslation } from "react-i18next"
import { useFormik } from "formik"
import { routeUrls } from "../../routes/routeUrls";
import { useLocation, useNavigate } from 'react-router-dom';
import {
	initialProfileSetupValues,
	profileSetupValidationSchema,
} from "./validation"
import {
	useGetUserProfileQuery,
	useProfileSetupMutation,
	useUpdateOtherProfilesMutation
} from "../../api/network/userApiService"
import { useEffect, useState } from "react"
import AppFormField from "../../components/AppFormField/AppFormField"
import { toast } from "react-toastify"
import AppLoader from "../../components/AppLoader/AppLoader"
import DeactivateUser from "../AdminPages/Users/DeactivateUser"
import { useActivateDeactivateUserMutation } from "../../api/network/userApiService"

interface IUserProfileProps {
	isPublicUserProfile?: boolean
	userId?: string
}

const UserProfile = ({
	isPublicUserProfile = false,
	userId,
}: IUserProfileProps) => {
	const [editToggle, setEditToggle] = useState(true)
	const [profileType, setProfileType] = useState(userId ? "other" : "own");
	const [profileSetUpTrigger] = useProfileSetupMutation()
	const [updateOtherProfileTrigger] = useUpdateOtherProfilesMutation()
	const [optionHandle, setOptionHandle] = useState("profile")
	const location = useLocation();
	const navigate = useNavigate();
	const { appUserRole } = location.state || {};

	const { t } = useTranslation("translation", {
		keyPrefix: "profileSetupPage",
	})
	const { data, isFetching, refetch } = useGetUserProfileQuery(
		isPublicUserProfile ? userId : undefined,
	)
	const [userStatusHandleTrigger] = useActivateDeactivateUserMutation()

	const handleProfileUpdate = (_values: any, profileType: string, userId: string | undefined) => {
		if (_values.phone_number === "") {
			toast.error("Please enter phone number")
			return
		} else if (_values.onsite_emergency_contact === "") {
			toast.error("Please enter onsite emergency contact")
			return
		}
		else if (_values.office_phone_number === "") {
			toast.error("Please enter office phone number")
			return
		}
		if (profileType === "own") {
			profileSetUpTrigger(_values)
				.unwrap()
				.then((response) => {
					toast.success("Profile updated successfully")
					refetch()
					setEditToggle(true)
				})
				.catch((error) => {
					toast.error(error.data?.detail || "Something went wrong")
				})
		} else if (profileType === "other") {
			updateOtherProfileTrigger({ id: userId ?? "1", payload: _values })
				.unwrap()
				.then((response) => {
					toast.success("Profile updated successfully")
					refetch()
					setEditToggle(true)
				})
				.catch((error) => {
					toast.error(error.data?.detail || "Something went wrong")
				})
		}
	}

	const formik = useFormik({
		initialValues: {
			...initialProfileSetupValues, // Fallback initial values
		},
		onSubmit: (values) => handleProfileUpdate(values, profileType, userId),
		validationSchema: profileSetupValidationSchema,
	})

	// Ensures the user is redirected to the dashboard when the back button is clicked
	useEffect(() => {
		if (profileType === "own") {
			navigate(window.location.pathname, { replace: true });
			const handleBackNavigation = () => {
				navigate(routeUrls.dashboard, { replace: true });
			};
			window.history.pushState(null, "", window.location.href);
			window.addEventListener("popstate", handleBackNavigation);

			return () => {
				window.removeEventListener("popstate", handleBackNavigation);
			};
		}
	}, [navigate, profileType]);


	useEffect(() => {
		if (location) {
			refetch();
		}
	}, [location]);

	const handleToggle = () => {
		setEditToggle(!editToggle)
	}

	const handleCancel = () => {
		setEditToggle(true)
		refetch()
		window.location.reload()
	}

	const handleUserStatus = () => {
		console.log("handleUserStatus")
		userStatusHandleTrigger({ id: userId })
			.unwrap()
			.then((response) => {
				toast.success("User status updated successfully")
				refetch()
			})
			.catch((error) => {
				toast.error(error.data?.detail || "Something went wrong")
			})
	}

	// Update form values with fetched data
	useEffect(() => {
		if (data) {
			formik.setValues({
				name: data.name || "",
				email: data.email || "",
				phone_number: data.phone_number || "",
				company: data.company || "",
				office_phone_number: data.office_phone_number || "",
				onsite_emergency_contact: data.onsite_emergency_contact || "",
				offsite_emergency_contact_number:
					data.offsite_emergency_contact_number || "",
				job_title: data.job_title || "", // Add this if job_title is in the response
			})
		}
	}, [data])

	if (isFetching) {
		return <AppLoader open />
	}

	return (
		<Box className="profile-user-container" display={"flex"}>
			<Box paddingLeft={2}>
				{isPublicUserProfile && (
					<DeactivateUser
						handleUserStatus={handleUserStatus}
						userStatus={(data && data.is_active) || false}
						role_type={'User'}
					/>
				)}
				<Box marginLeft={3}>
					<Box display={"flex"} justifyContent={"space-between"}>
						<form onSubmit={formik.handleSubmit}>
							<Grid2
								width={"100%"}
								marginLeft={4}
								sx={{
									bgcolor: "white",
									border: "2px solid #fff",
									borderRadius: 8,
									boxShadow: 4,
									padding: 3,
									marginTop: 4,
								}}
							>
								{!isPublicUserProfile || (appUserRole === "Company Admin" || appUserRole === "Super Admin") ? (
									<Box display={"flex"} justifyContent={"space-between"}>
										<Typography className="title">Profile</Typography>
										{editToggle ? (
											<AppButton
												type="button"
												btnSize="medium"
												sx={{ marginLeft: 1, marginBottom: 2 }}
												onClick={handleToggle} // Call handleSubmit
											>
												{t("edit_button")}
											</AppButton>
										) : (
											<Grid2 marginBottom={2}>
												<AppButton
													type="button"
													btnSize="medium"
													sx={{
														marginLeft: 1,
														width: 100,
														backgroundColor: "white",
														color: "#1B2944",
														border: "1px solid #1B2944",
													}}
													onClick={handleCancel} // Call handleSubmit
												>
													{t("cancel_button")}
												</AppButton>
												<AppButton
													type="submit"
													btnSize="medium"
													sx={{
														marginLeft: 1,
														width: 100,
														backgroundColor: "#54AE2A",
														color: "white",
													}}
												// onClick={formik.handleSubmit} // Call handleSubmit
												>
													{t("save_button")}
												</AppButton>
											</Grid2>
										)}
									</Box>
								) : null}
								<Box display={"flex"} justifyContent={"space-between"} gap={-6}>
									<Box>
										<Typography className="title2">Personal Details</Typography>
										<Grid2 size={6}>
											<AppFormField
												label={t("name")}
												name="name"
												placeholder={t("name")}
												value={formik.values.name}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												touched={formik.touched.name}
												errorMsg={
													formik.errors.name ? t(formik.errors.name) : ""
												}
												inputProps={{
													disabled: editToggle,
												}}
											/>
										</Grid2>
										<Grid2 size={6} width={250}>
											<AppFormField
												label={t("email")}
												name="email"
												placeholder={t("email")}
												value={formik.values.email}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												touched={formik.touched.email}
												errorMsg={
													formik.errors.email ? "Email is required" : ""
												}
												inputProps={{
													disabled: true,
												}}
											/>
										</Grid2>
										<Grid2 size={6}>
											<AppFormField
												label={t("phone_number")}
												name="phone_number"
												type="text"
												placeholder={t("phone_number")}
												value={formik.values.phone_number}
												onChange={(e) => {
													const value = e.target.value;
													if (/[^0-9]/.test(value)) {
														const numericValue = value.replace(/[^0-9]/g, "");
														formik.handleChange({ target: { name: "phone_number", value: numericValue } });
													} else {
														formik.handleChange(e);
													}
												}}
												onBlur={formik.handleBlur}
												touched={formik.touched.phone_number}
												errorMsg={
													formik.errors.phone_number
														? formik.errors.phone_number
														: ""
												}
												inputProps={{
													disabled: editToggle,
													// onKeyDown: (e) => {
													// 	if (["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(e.key)) {
													// 		e.preventDefault();
													// 	}
													// },
													// onWheel: (e) => e.preventDefault(), // Prevent mouse wheel scroll
												}}
											/>
										</Grid2>
									</Box>
									<Box>
										<Typography className="title2">
											Professional Details
										</Typography>
										<Grid2 size={6}>
											<AppFormField
												label={t("company_name")}
												name="company"
												placeholder={t("company_name")}
												value={formik.values.company}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												touched={formik.touched.company}
												errorMsg={
													formik.errors.company
														? "Company name is required"
														: ""
												}
												inputProps={{
													disabled: true,
												}}
											/>
										</Grid2>
										<Grid2 size={6}>
											<AppFormField
												label={t("job_title")}
												name="job_title"
												placeholder={t("job_title")}
												value={formik.values.job_title}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												touched={formik.touched.job_title}
												errorMsg={
													formik.errors.job_title
														? "Job Title is required"
														: ""
												}
												inputProps={{
													disabled: editToggle,
												}}
											/>
										</Grid2>
										<Grid2 size={6}>
											<AppFormField
												label={t("office_phone_number")}
												name="office_phone_number"
												placeholder={t("office_phone_number")}
												type="text"
												value={formik.values.office_phone_number}
												onChange={(e) => {
													const value = e.target.value;
													if (/[^0-9]/.test(value)) {
														const numericValue = value.replace(/[^0-9]/g, "");
														formik.handleChange({ target: { name: "office_phone_number", value: numericValue } });
													} else {
														formik.handleChange(e);
													}
												}}
												onBlur={formik.handleBlur}
												touched={formik.touched.office_phone_number}
												errorMsg={
													formik.errors.office_phone_number
														? formik.errors.office_phone_number
														: ""
												}
												inputProps={{
													disabled: editToggle,
												}}
											/>
										</Grid2>
										<Grid2 size={6}>
											<AppFormField
												label={t("onsite_emergency_contact_number")}
												name="onsite_emergency_contact"
												placeholder={t("onsite_emergency_contact_number")}
												type="text"
												value={formik.values.onsite_emergency_contact}
												onChange={(e) => {
													const value = e.target.value;
													if (/[^0-9]/.test(value)) {
														const numericValue = value.replace(/[^0-9]/g, "");
														formik.handleChange({ target: { name: "onsite_emergency_contact", value: numericValue } });
													} else {
														formik.handleChange(e);
													}
												}}
												onBlur={formik.handleBlur}
												touched={formik.touched.onsite_emergency_contact}
												errorMsg={
													formik.errors.onsite_emergency_contact
														? formik.errors.onsite_emergency_contact
														: ""
												}
												inputProps={{
													disabled: editToggle,
												}}
											/>
										</Grid2>
										<Grid2 size={6}>
											<AppFormField
												label={t("offsite_emergency_contact_number")}
												name="offsite_emergency_contact_number"
												placeholder={t("offsite_emergency_contact_number")}
												type="text"
												value={formik.values.offsite_emergency_contact_number}
												onChange={(e) => {
													const value = e.target.value;
													if (/[^0-9]/.test(value)) {
														const numericValue = value.replace(/[^0-9]/g, "");
														formik.handleChange({ target: { name: "offsite_emergency_contact_number", value: numericValue } });
													} else {
														formik.handleChange(e);
													}
												}}
												onBlur={formik.handleBlur}
												touched={
													formik.touched.offsite_emergency_contact_number
												}
												errorMsg={
													formik.errors.offsite_emergency_contact_number
														? formik.errors.offsite_emergency_contact_number
														: ""
												}
												inputProps={{
													disabled: editToggle,
												}}
											/>
										</Grid2>
									</Box>
								</Box>
							</Grid2>
						</form>
					</Box>
				</Box>
			</Box>
		</Box>
	)
}

export default UserProfile
