import { Box, Grid2, Typography } from "@mui/material"
import { Item } from "./SwimlaneData"
import "./ActivityCard.scss"
import { Svgs } from "../../assets/svgs"
import { useState } from "react"
import SwimlaneActivityDetail from "./SwimlaneActivityDetail"
import { ActivityDetail } from "../../api/types/Project"

const ActivityCard = (activityItem: Item) => {
	const { activity_id, activity_name, details, status, id, type } = activityItem
	const [isDrawerOpen, setIsDrawerOpen] = useState(false)
	const toggleDrawer = () => {
		setIsDrawerOpen((prev) => !prev)
	}

	return (
		<>
			{
				<SwimlaneActivityDetail
					openDrawer={isDrawerOpen}
					onToggleDrawer={toggleDrawer}
					activityItem={activityItem}
				/>
			}
			<div className={`card-container`}>
				<Grid2 display={"flex"} className="card">
					<Typography className="card-title">{activity_name}</Typography>
					<Typography onClick={toggleDrawer}>
						<Svgs.EyeOutlineBlue />
					</Typography>
					<Typography className="float-text">
						{details?.total_float && details?.total_float < 0
							? details.total_float
							: null}
					</Typography>
				</Grid2>
				{type && (
					<Typography variant="body2" className={`card-type ${type}`}>
						{type}
					</Typography>
				)}
			</div>
		</>
	)
}

export default ActivityCard
