import { Box, Grid2 } from "@mui/material"
import "./Feedback.scss"
import AppAutocompleteFormField from "../../components/AppFormField/AppAutocompleteFormField"
import AppFormField from "../../components/AppFormField/AppFormField"
import AppButton from "../../components/AppButton/AppButton"
import {
	AddFeedbackInitialValue,
	AddFeedbackValidationSchema,
} from "./validation"
import { useFormik } from "formik"
import { toast } from "react-toastify"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { routeUrls } from "../../routes/routeUrls"
import { useSendFeedbackMutation } from "../../api/network/projectApiService"
import { useState, useEffect } from "react"

export default function Feedback() {
	const navigate = useNavigate()
	const [sendFeedbackTrigger] = useSendFeedbackMutation()
	const [sendingFeedback, setSendingFeedback] = useState(false)
	const { t } = useTranslation("translation", { keyPrefix: "feedback" })

	const options = [
		{ id: "1", label: "Feedback", value: "Feedback" },
		{ id: "2", label: "Suggestion", value: "Suggestion" },
		{ id: "3", label: "Issue", value: "Issue" },
	]

	const handleAddFeedback = (values: any) => {
		setSendingFeedback(true)
		console.log("Feedback values", values)
		const payload = {
			feedback_type:
				values.type == 1
					? "Feedback"
					: values.type == 2
						? "Suggestion"
						: "Issue",
			description: values.message,
		}
		console.log("Feedback payload", payload)
		sendFeedbackTrigger(payload)
			.unwrap()
			.then((response) => {
				setSendingFeedback(false)
				toast.success("Feedback sent successfully")
				setFieldValue("type", "")
				resetForm()
			})
			.catch((error) => {
				toast.error("Failed to send feedback")
				console.log("Feedback error", error)
			})
	}

	const handleMessageChange = (e: any) => {
		console.log("Message change", e.target.value)
		const len: any = e.target.value.length
		if (len > 500) {
			console.log("Message length should be less than 500 characters")
		} else {
			handleChange(e)
		}
	}

	const {
		values,
		errors,
		touched,
		handleChange,
		setFieldValue,
		handleBlur,
		handleSubmit,
		resetForm,
	} = useFormik({
		initialValues: {
			...AddFeedbackInitialValue,
		},
		onSubmit: handleAddFeedback,
		validationSchema: AddFeedbackValidationSchema,
	})

	// Ensures the user is redirected to the dashboard when the back button is clicked
	useEffect(() => {
		navigate(window.location.pathname, { replace: true });
		const handleBackNavigation = () => {
			navigate(routeUrls.dashboard, { replace: true });
		};
		window.history.pushState(null, "", window.location.href);
		window.addEventListener("popstate", handleBackNavigation);

		return () => {
			window.removeEventListener("popstate", handleBackNavigation);
		};
	}, [navigate]);

	return (
		<Box
			marginLeft={10}
			width={320}
			className="feedback-container"
			sx={{
				bgcolor: "white",
				border: "2px solid #fff",
				borderRadius: 8,
				boxShadow: 4,
				padding: 3,
				marginTop: 4,
			}}
		>
			<Grid2 className="title">Feedback/Suggestion</Grid2>
			<form onSubmit={handleSubmit}>
				<Grid2 className="title2" marginTop={3}>
					<AppAutocompleteFormField
						options={options}
						label={"Type *"}
						name="type"
						placeholder={"Please select the type"}
						value={values.type}
						onChange={setFieldValue}
						onBlur={handleBlur}
						touched={touched.type}
						errorMsg={errors.type ? "Please select type" : ""}
					/>
				</Grid2>

				<Grid2 className="title2" marginTop={3}>
					<AppFormField
						label={"Message *"}
						name={"message"}
						placeholder={"Message"}
						value={values.message}
						onChange={handleMessageChange}
						onBlur={handleBlur}
						touched={touched.message}
						errorMsg={errors.message ? "Message is required" : ""}
						inputProps={{
							multiline: true,
							rows: 2,
						}}
					/>
				</Grid2>
				<Grid2>
					<AppButton
						btnSize={"medium"}
						fullWidth={true}
						disabled={sendingFeedback}
						variant="contained"
						style={{ marginTop: 8, }}
						type="submit"
					>
						{t("submit")}
					</AppButton>
				</Grid2>
			</form>
		</Box>
	)
}
