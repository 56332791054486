import { Box, Grid2 } from "@mui/material"
import { useTranslation } from "react-i18next"
import AppFormField from "../../components/AppFormField/AppFormField"
import { IAddSubActivitySectionFormProps } from "./validation"
import { useGetProjectTradesQuery } from "../../api/network/projectApiService"
import { useMemo } from "react"
import AppAutocompleteFormField from "../../components/AppFormField/AppAutocompleteFormField"

interface ISubActivityTradeFormProps
	extends IAddSubActivitySectionFormProps {
	projectId: any
}

export default function SubActivityTrade(
	{
		values,
		errors,
		touched,
		handleChange,
		setFieldValue,
		disabled,
		handleBlur,
		projectId
	}: ISubActivityTradeFormProps
) {
	const { t } = useTranslation("translation", { keyPrefix: "subActivity" })
	const { t: tErrors } = useTranslation("translation", {
		keyPrefix: "subActivity.errors",
	})
	console.log("Sub Activity Trade : ", values);

	const { data, isFetching } = useGetProjectTradesQuery(projectId)
	const handleSubActivityCodeChange = (event: any, value: any) => {
		console.log("Trade Sub Activity Value", value?.value);
		setFieldValue("trade", value)
	}
	const subActivityCodes = useMemo(() => {
		return data?.results?.map((trade: any) => ({
			value: trade.id,
			label: trade.actv_code_name,
		})) || []
	}
		, [data?.results])


	return (
		<Box
			sx={{
				bgcolor: "white",
				border: "2px solid #fff",
				borderRadius: "12px",
				boxShadow: 4,
				padding: 3,
				display: "flex",
				flexDirection: "column",
				gap: "16px",
			}}
		>
			<Grid2 display={"flex"} justifyContent={"space-between"}>
				<Grid2 className="title">{t("type")}</Grid2>
			</Grid2>
			<Grid2>
				{/* <AppFormField
					label={t("trade_responsible")}
					isRequired
					name={"trade"}
					placeholder={t("select_trade")}
					value={values.trade}
					onChange={handleChange}
					onBlur={handleBlur}
					touched={touched.trade}
					disabled={disabled}
					errorMsg={errors.trade ? tErrors('trade_responsible') : ""}
				/> */}
				<AppAutocompleteFormField
					label={t("sub_activity_code")}
					// isRequired
					name={"trade"}
					showLoader={isFetching}
					placeholder={t("select_type")}
					value={values.trade}
					disabled={disabled}
					options={subActivityCodes || []}
					onChange={handleSubActivityCodeChange}
					onBlur={handleBlur}
					errorMsg={errors.trade ? tErrors('trade') : ""}
				/>
			</Grid2>
		</Box>
	)
}
