import { Box, Grid2 } from "@mui/material"
import React, { useMemo } from "react"
import AppButton from "../../components/AppButton/AppButton"
import AppAutocompleteFormField from "../../components/AppFormField/AppAutocompleteFormField"
import { useTranslation } from "react-i18next"
import { IAddActivitySectionFormProps } from "./validation"
import { useGetProjectTradesQuery } from "../../api/network/projectApiService"

interface IActivityTradeFormProps
	extends IAddActivitySectionFormProps {
	projectId: number
}

export default function ActivityTrade({
	values,
	errors,
	touched,
	handleChange,
	setFieldValue,
	disabled,
	handleBlur,
	projectId
}: IActivityTradeFormProps) {
	const { t } = useTranslation("translation", { keyPrefix: "activity" })
	const { data, isFetching } = useGetProjectTradesQuery(projectId)
	const handleActivityCodeChange = (event: any, value: any) => {
		setFieldValue("trade_responsible", value)
	}
	const activityCodes = useMemo(() => {
		return data?.results?.map((trade: any) => ({
			value: trade.id,
			label: trade.actv_code_name,
		})) || []
	}
		, [data?.results])

	return (
		<Box
			sx={{
				bgcolor: "white",
				border: "2px solid #fff",
				borderRadius: "12px",
				boxShadow: 4,
				padding: 3,
				display: "flex",
				flexDirection: "column",
				gap: "16px",
			}}
		>
			<Grid2 display={"flex"} justifyContent={"space-between"}>
				<Grid2 className="title">{t("type")}</Grid2>
				{/* <Grid2>
					<AppButton variant="outlined">{t("add_a_trade")}</AppButton>
				</Grid2> */}
			</Grid2>
			<Grid2>
				<AppAutocompleteFormField
					label={t("activity_code")}
					// isRequired
					name={"trade_responsible"}
					showLoader={isFetching}
					placeholder={t("select_type")}
					value={values.trade_responsible}
					disabled={disabled}
					options={activityCodes || []}
					onChange={handleActivityCodeChange}
					onBlur={handleBlur}
				/>
			</Grid2>
		</Box>
	)
}
