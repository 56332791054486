import { Box, CircularProgress, Grid2 } from "@mui/material"
import { format as dateFnsFormat } from "date-fns"
import "./AddActivity.scss"
import ActivityGeneralDetailsForm from "./ActivityGeneralDetailsForm"
import ActivityDateTime from "./ActivityDateTime"
import ActivityRelation from "./ActivityRelation"
import ActivityOtherDetails from "./ActivityOtherDetails"
import { useFormik } from "formik"
import {
	AddActivityInitialValue,
	AddActivityValidationSchema,
	IActivity,
	ICreateActivity,
} from "./validation"
// import ActivityDays from "./ActivityDays"
import DetailNavBar from "../../components/DetailNavBar/DetailNavBar"
import AppButton from "../../components/AppButton/AppButton"
import { useTranslation } from "react-i18next"
import { useCreateActivityMutation } from "../../api/network/projectApiService"
import { toast } from "react-toastify"
import { useLocation, useNavigate } from "react-router-dom"
import { routeUrls } from "../../routes/routeUrls"
import { CreateActivityPayload } from "../../api/types/Project"
import ActivitySuccessors from "./ActivitySuccessors"
import ActivityTrade from "./ActivityTrade"

export default function AddActivity() {
	const { t } = useTranslation("translation", { keyPrefix: "activity" })

	const navigate = useNavigate()
	const location = useLocation()
	console.log("Location@", location?.state)
	const [createActivityTrigger, { isLoading: isSavingActivity }] =
		useCreateActivityMutation()

	const projectId = location?.state?.project?.id

	const handleAddActivity = (_value: ICreateActivity) => {
		const activityPayload: CreateActivityPayload = {
			project: projectId,
			activity_type: _value.activity_type,
			activity_name: _value.activity_name,
			wbs_code: _value.wbs_code,
			wbs_name: _value.wbs_name,
			trade_responsible: _value?.trade_responsible?.label?.toString(),
			session: location?.state?.project?.active_session,
			planned_start_date: `${dateFnsFormat(
				new Date(_value.planned_start_date ?? ""),
				"yyyy-MM-dd",
			)} 08:00:00`,
			planned_finish_date: `${dateFnsFormat(
				new Date(_value.planned_finish_date ?? ""),
				"yyyy-MM-dd",
			)} 17:00:00`,
			assignee: _value.assignee,
			calendar: {
				working_days: _value.calendar_working_days,
			},
			predecessors: [],
			notes: _value.activity_notes,
			successors: [],
		}
		if (_value.actual_start_date) {
			activityPayload.actual_start_date = dateFnsFormat(
				new Date(_value.actual_start_date ?? ""),
				"yyyy-MM-dd hh:mm:ss",
			)
		}
		if (_value.actual_finish_date) {
			activityPayload.actual_finish_date = dateFnsFormat(
				new Date(_value.actual_finish_date ?? ""),
				"yyyy-MM-dd hh:mm:ss",
			)
		}
		if (_value.predecessors.length > 0) {
			console.log("Predecessors For Add Activity : ", values.predecessors)
			activityPayload.predecessors = _value.predecessors.map((predecessor) => {
				return {
					predecessor: predecessor.id,
					relationship_type: predecessor.relationship_type,
					lag_days: predecessor.lag_days || 0,
				}
			})
		}
		if (_value.successors.length > 0) {
			console.log("Successors For Add Activity : ", values.successors)
			activityPayload.successors = _value.successors.map((successors) => {
				return {
					successor: successors.id,
					relationship_type: successors.relationship_type,
					lag_days: successors.lag_days || 0,
				}
			})
		}
		console.log("Activity values", _value, activityPayload)
		createActivityTrigger(activityPayload)
			.unwrap()
			.then((res) => {
				console.log("Activity created", res)
				toast.success(t("activity_created_successfully"))
				// navigate(routeUrls.activity.list)
				navigate(routeUrls.projects.swimlane(projectId.toString()))
			})
			.catch((err) => {
				console.log("Activity error", err)
				toast.error(t("activity_creation_error"))
			})
	}

	const {
		values,
		errors,
		touched,
		handleChange,
		setFieldValue,
		handleBlur,
		handleSubmit,
		// resetForm,
	} = useFormik({
		initialValues: {
			...AddActivityInitialValue,
		},
		onSubmit: handleAddActivity,
		validationSchema: AddActivityValidationSchema,
	})

	return (
		<Box className="activity-form-container">
			<form onSubmit={handleSubmit}>
				<DetailNavBar
					title={t("add_an_activity")}
					showBackButton={false}
					rightView={
						<Grid2 display={"flex"} gap={2}>
							<AppButton
								btnSize="medium"
								variant="outlined"
								color="error"
								disabled={isSavingActivity}
								onClick={() => navigate(-1)}>
								{t("cancel")}
							</AppButton>
							<AppButton
								btnSize="medium"
								type="submit"
								disabled={isSavingActivity}
							>
								{t("add_activity")}
							</AppButton>
						</Grid2>
					}
				/>
				<ActivityGeneralDetailsForm
					projectId={projectId}
					values={values}
					errors={errors}
					touched={touched}
					handleChange={handleChange}
					handleBlur={handleBlur}
					setFieldValue={setFieldValue}
					disabled={false}
				/>
				<ActivityDateTime
					values={values}
					errors={errors}
					touched={touched}
					handleChange={handleChange}
					handleBlur={handleBlur}
					setFieldValue={setFieldValue}
					disabled={false}
					mode="add"
				/>
				{/* <ActivityDays
					values={values}
					errors={errors}
					touched={touched}
					handleChange={handleChange}
					handleBlur={handleBlur}
					setFieldValue={setFieldValue}
					disabled={false}
				/> */}
				<ActivityTrade
					values={values}
					projectId={projectId}
					errors={errors}
					touched={touched}
					handleChange={handleChange}
					handleBlur={handleBlur}
					setFieldValue={setFieldValue}
					disabled={false}
				/>
				<ActivityRelation
					values={values}
					errors={errors}
					touched={touched}
					handleChange={handleChange}
					handleBlur={handleBlur}
					setFieldValue={setFieldValue}
					disabled={false}
				/>
				<ActivitySuccessors
					values={values}
					errors={errors}
					touched={touched}
					handleChange={handleChange}
					handleBlur={handleBlur}
					setFieldValue={setFieldValue}
					disabled={false}
				/>
				<ActivityOtherDetails
					values={values}
					errors={errors}
					touched={touched}
					handleChange={handleChange}
					handleBlur={handleBlur}
					setFieldValue={setFieldValue}
					disabled={false}
				/>
			</form>
		</Box >
	)
}
