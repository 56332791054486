import React, { useCallback, useEffect, useState } from "react"
import Timeline, {
	DateHeader,
	OnItemDragObjectBase,
	TimelineHeaders,
} from "react-calendar-timeline"
import moment from "moment"
import "react-calendar-timeline/lib/Timeline.css"
import "./Swimlane.scss"
import { Item, Lines, SwimlaneSection } from "./SwimlaneData"
import { Box, Button, Grid2, Typography } from "@mui/material"
import {
	useGetSwimlaneBasedActivitiesQuery,
	useLazyGetProjectCalendarQuery,
	useLazyGetSwimlanesListByActivityCodeQuery,
	useUpdateActivityMutation,
	useUpdateSwimlaneStateMutation,
} from "../../api/network/projectApiService"
import {
	getEmptyDataGroups,
	getGroupsFromActivties,
	getSwimlaneItemsFromActivity,
} from "./SwimlaneUtils"
import { RootReduxState } from "../../api/store/store"
import { useSelector } from "react-redux"
import AppLoader from "../../components/AppLoader/AppLoader"
import AppAutocompleteFilter from "../../components/AppDropdownFilter/AppAutocompleteFilter"
import ActivityCard from "./ActivityCard"
import { toast } from "react-toastify"
import { format as dateFnsFormat, set } from "date-fns"
import { ProjectUserRole } from "../../api/types/Auth"
import { ProjectDetails } from "../../api/types/Project"
import LineConnect from "../../components/LineConnect/LineConnect"
import { Xwrapper } from "react-xarrows"
import { updateSwimlaneNav } from "../../api/slices/commonSlice"
import { useDispatch } from "react-redux"
import { CustomSwitch } from "../../components/AppSwitch/CustomSwitch"

const CustomItemRenderer = ({
	item,
	itemContext,
	getItemProps,
	getResizeProps,
}: any) => {
	const { left: leftResizeProps, right: rightResizeProps } = getResizeProps()
	return (
		<div
			id={`${item.id}`}
			{...getItemProps({
				style: {
					background: "white",
					border: "1px solid white",
					color: "black",
					position: "absolute",
					// zIndex: itemContext.stack ? itemContext.stack : 1,
				},
			})}
		>
			{itemContext.useResizeHandle ? <div {...leftResizeProps} /> : ""}
			{/* big card or small card condition will be implemented here*/}
			<ActivityCard
				{...item}
				start_time={
					new Date(moment(item.actual_start_date).format("YYYY-MM-DD"))
				}
				end_time={
					new Date(moment(item.actual_finish_date).format("YYYY-MM-DD"))
				}
				activityDetails={item}
			/>

			{itemContext.useResizeHandle ? <div {...rightResizeProps} /> : ""}
		</div>
	)
}

interface SwimlaneCalenderProps {
	session: any
	projectId: number | undefined
	swimlaneWbsListShowStatus: boolean
	swimlaneActivityListShowStatus: boolean
	editable: boolean
	projectData: ProjectDetails | undefined
	viewerReload?: number | null
}

const SwimlaneCalender: React.FC<SwimlaneCalenderProps> = (props) => {
	const { projectId, session, editable, projectData } = props
	const dispatch = useDispatch()
	const [renderKey, setRenderKey] = useState<{ [key: string]: number }>({})
	const [cards, setCards] = useState<{ [key: string]: Item[] }>({})
	const [lines, setLines] = useState<{ [key: string]: Lines[] }>({})
	const [activityFilter, setActivityFilter] = useState<string>()
	const [swimlaneActivityList, setSwimlaneActivityList] = useState<any[]>([])
	const [sections, setSections] = useState<
		{ [key: string]: SwimlaneSection } | undefined
	>(undefined)
	const [showRelations, setShowRelations] = useState<boolean>(false)
	const [relationToggleEnabled, setRelationToggleEnabled] =
		useState<boolean>(false)
	const [datePivotToLoad, setDatePivotToLoad] = useState<string>(
		moment().format("YYYY-MM-DD"),
	)
	const DAY_BUFFER = 7
	const minTime = moment().subtract(DAY_BUFFER, "d").valueOf()
	const maxTime = moment().add(DAY_BUFFER, "d").valueOf()
	const minZoom = DAY_BUFFER * 2 * 24 * 60 * 60 * 1000 // Min zoom is 28 days
	const maxZoom = DAY_BUFFER * 4 * 24 * 60 * 60 * 1000 // Max zoom is 56 days
	const [visibleSection, setVisibleSection] = useState({
		visibleTimeStart: minTime,
		visibleTimeEnd: maxTime,
	})
	const [editing, setEditing] = useState(false)
	const isViewer = projectData?.current_user_role === ProjectUserRole.Viewer

	const swimlaneNavState = useSelector(
		(state: RootReduxState) => state.commonSliceData,
	)
	const [updateActivityApiTrigger, { isLoading: isUpdatingActivity }] =
		useUpdateActivityMutation()
	const [
		getSwimlanesByActivity,
		{ data: activityData, isFetching: isFetchingActivity },
	] = useLazyGetSwimlanesListByActivityCodeQuery()
	const [
		updateStateApiTrigger,
		{ isLoading: isUpdatingState, isUninitialized },
	] = useUpdateSwimlaneStateMutation()
	const [getProjectCalendar, { data: projectCalendarData }] =
		useLazyGetProjectCalendarQuery()

	useEffect(() => {
		if (projectId) {
			getProjectCalendar(projectId?.toString())
		}
	}, [projectId])

	const {
		data: allData,
		isFetching,
		refetch,
	} = useGetSwimlaneBasedActivitiesQuery(
		{
			project: projectId ?? 0,
			...((props?.swimlaneActivityListShowStatus &&
				swimlaneNavState?.selected_activity_code) ||
			activityFilter
				? {
						activity_code: (swimlaneNavState?.selected_activity_code ||
							activityFilter) as string,
					}
				: 0),
			...(props?.swimlaneWbsListShowStatus && swimlaneNavState?.selected_wbs
				? { wbs: swimlaneNavState?.selected_wbs }
				: 0),
			// start_date: datePivotToLoad,
			session: session,
		},
		{
			skip: !projectId || visibleSection === null || editing,
			// pollingInterval: 1000 * 5, // 5 seconds
		},
	)

	useEffect(() => {
		if (props?.viewerReload) {
			refetch()
		}
	}, [props?.viewerReload])

	/**
	 * Effect to create group sections to be used in each swimlane.
	 * Each section will have multiple groups based on the no. of activities within swimlane.
	 * Each group will have unique id and title. Also, it'll host one activity card.
	 */
	useEffect(() => {
		if (allData) {
			const newSections: { [key: string]: SwimlaneSection } = {}
			if (allData?.main_swimlane && allData?.main_swimlane?.length > 0) {
				newSections["0"] = {
					name: "Main Swimlane",
					groups: getGroupsFromActivties(allData.main_swimlane),
				}
			}
			if (allData.sub_swimlanes && allData?.sub_swimlanes?.length > 0) {
				allData.sub_swimlanes?.forEach((subSwimlane: any) => {
					newSections[subSwimlane.code] = {
						name: subSwimlane.name,
						groups: getGroupsFromActivties(subSwimlane.activities),
					}
				})
			}
			if (
				allData?.main_swimlane?.length === 0 &&
				allData?.sub_swimlanes?.length === 0
			) {
				// Add empty section if no activities are present
				newSections["0"] = {
					name: "Main Swimlane",
					groups: getEmptyDataGroups(),
				}
			}
			// if (sections) {
			// 	let isSameData = true
			// 	for (const sectionId in sections) {
			// 		if (!newSections[sectionId]) {
			// 			isSameData = false
			// 			break
			// 		}
			// 	}
			// 	if (!isSameData) {
			// 		setSections(newSections)
			// 	}
			// } else {
			// 	setSections(newSections)
			// }
			setSections(newSections)
		}
	}, [allData, projectId, swimlaneNavState, editable])

	/**
	 * Effect to create cards for each swimlane.
	 */
	useEffect(() => {
		if (allData && sections) {
			const newCards: { [key: string]: Item[] } = {}
			const newLines: { [key: string]: Lines[] } = {}
			const renderKeys: { [key: string]: number } = {}

			if (allData?.main_swimlane && allData?.main_swimlane?.length > 0) {
				Object.keys(sections).forEach((sectionId) => {
					newCards[sectionId] = getSwimlaneItemsFromActivity(
						allData.main_swimlane,
						sections[sectionId].groups,
						editable,
					)
					// Add lines to state for each card array
					newLines[sectionId] = getLinesFromCards(newCards[sectionId])
					renderKeys[sectionId] = Math.random()
				})
			}
			if (allData.sub_swimlanes && allData.sub_swimlanes.length > 0) {
				allData.sub_swimlanes?.forEach((subSwimlane: any) => {
					newCards[subSwimlane.code] = getSwimlaneItemsFromActivity(
						subSwimlane.activities,
						sections[subSwimlane.code]?.groups || [],
						editable,
					)
					// Add lines to state for each card array
					newLines[subSwimlane.code] = getLinesFromCards(
						newCards[subSwimlane.code],
					)
					renderKeys[subSwimlane.code] = Math.random()
				})
			}
			setCards(newCards)
			setLines(newLines)
			setRenderKey(renderKeys)
		}
	}, [allData, sections, editable])

	/**
	 * Method to update the planned start and end date of an activity
	 * @param activityId Id of the activity to be updated
	 * @param startDate Start date of the activity
	 * @param endDate End date of the activity
	 */
	const updateActivityPlannedDates = (
		activityId: string,
		startDate: number,
		endDate: number,
	) => {
		const payload: { id: string; [key: string]: any } = {
			id: activityId,
		}
		payload["session"] = session
		payload["planned_start_date"] = dateFnsFormat(
			new Date(startDate),
			"yyyy-MM-dd HH:mm:ss",
		)
		payload["planned_finish_date"] = dateFnsFormat(
			new Date(endDate),
			"yyyy-MM-dd HH:mm:ss",
		)

		updateActivityApiTrigger(payload)
			.unwrap()
			.then((res) => {
				setEditing(false)
				// toast.success("Activity updated successfully")
				// window.location.reload()
			})
			.catch((err) => {
				setEditing(false)
			})
	}

	const handleTimeChange = (start: any, end: any, updateScrollCanvas: any) => {
		// let startTo
		// let endTo

		// if (start < minTime) {
		// 	startTo = minTime
		// 	endTo = minTime + (end - start)
		// 	// updateScrollCanvas(minTime, minTime + (end - start))
		// } else if (end > maxTime) {
		// 	startTo = maxTime - (end - start)
		// 	endTo = maxTime
		// 	// updateScrollCanvas(maxTime - (end - start), maxTime)
		// } else {
		// 	startTo = start
		// 	endTo = end
		// 	// updateScrollCanvas(start, end)
		// }
		// setVisibleSection({
		// 	visibleTimeStart: startTo,
		// 	visibleTimeEnd: endTo,
		// })
		setVisibleSection({
			visibleTimeStart: start,
			visibleTimeEnd: end,
		})
		// Hide the relation lines if the timeline is moved too far
		if (
			moment(start).toDate() < moment(minTime).subtract(20, "d").toDate() ||
			moment(end).toDate() > moment(maxTime).add(20, "d").toDate()
		) {
			if (relationToggleEnabled) setShowRelations(false)
		} else if (relationToggleEnabled) {
			setShowRelations(true)
			redrawLinks()
		}
	}

	const onItemDrag = (itemDragObject: OnItemDragObjectBase) => {
		setEditing(true)
	}

	const onItemResize = (id: number, time: number, edge: "left" | "right") => {
		// Retrieve only date part from time(Reduce 1 day if planned end date is changed, to accomodate edge settings of calendar)
		const updatedDate = moment(time)
			.subtract(edge === "right" ? 1 : 0, "d")
			.format("YYYY-MM-DD")
		// Update card start or end time based on edge
		setCards((prevCards) => {
			const newCards = { ...prevCards }
			Object.keys(newCards).forEach((sectionId) => {
				newCards[sectionId] = newCards[sectionId].map((card: Item) => {
					if (card.id === id) {
						// Get cards planned time
						const plannedTime = moment(
							edge === "left"
								? card.planned_start_date
								: card.planned_finish_date,
							"YYYY-MM-DDTHH:mm:ssZ",
						)
							.format("HH:mm:ss")
							.toString()
						updateActivityPlannedDates(
							id.toString(),
							edge === "left"
								? moment(`${updatedDate}T${plannedTime}`).utc().valueOf()
								: card.start_time.getTime(),
							edge === "left"
								? moment(card.end_time).subtract(1, "d").utc().valueOf()
								: moment(`${updatedDate}T${plannedTime}`).utc().valueOf(),
						)
						return {
							...card,
							[edge === "left" ? "start_time" : "end_time"]: new Date(time),
							[edge === "left" ? "planned_start_date" : "planned_finish_date"]:
								moment(time).format("YYYY-MM-DDTHH:mm:ssZ"),
						}
					} else {
						return card
					}
				})
			})
			return newCards
		})
	}

	const onItemMove = (id: number, time: number) => {
		// Find actvity in each section and update the start and end time
		setCards((prevCards) => {
			const newCards = { ...prevCards }
			Object.keys(newCards).forEach((sectionId) => {
				newCards[sectionId] = newCards[sectionId].map((card) => {
					if (card.id === id) {
						updateActivityPlannedDates(
							id.toString(),
							time,
							time +
								card.end_time.getTime() -
								card.start_time.getTime() -
								1 * 24 * 60 * 60 * 1000,
						) // Subtract 1 day to accomodate calendar library UI changes
						return {
							...card,
							start_time: new Date(time),
							end_time: new Date(
								time + card.end_time.getTime() - card.start_time.getTime(),
							),
							planned_start_date: moment(time).format("YYYY-MM-DDTHH:mm:ssZ"),
							planned_finish_date: moment(
								time +
									card.end_time.getTime() -
									card.start_time.getTime() -
									1 * 24 * 60 * 60 * 1000,
							).format("YYYY-MM-DDTHH:mm:ssZ"), // Subtract 1 day to accomodate calendar library UI changes
						}
					} else {
						return card
					}
				})
			})
			return newCards
		})
	}

	const handleBack = () => {
		setVisibleSection((prev) => ({
			visibleTimeStart: moment(prev.visibleTimeStart)
				.subtract(7, "days")
				.valueOf(),
			visibleTimeEnd: moment(prev.visibleTimeEnd).subtract(7, "days").valueOf(),
		}))
		setDatePivotToLoad((lastPivot) =>
			moment(lastPivot).subtract(7, "d").format("YYYY-MM-DD"),
		)
		refetch()
	}

	// Move timeline forward by 15 days
	const handleNext = () => {
		setVisibleSection((prev) => ({
			visibleTimeStart: moment(prev.visibleTimeStart).add(7, "days").valueOf(),
			visibleTimeEnd: moment(prev.visibleTimeEnd).add(7, "days").valueOf(),
		}))
		setDatePivotToLoad((lastPivot) =>
			moment(lastPivot).add(7, "d").format("YYYY-MM-DD"),
		)
		refetch()
	}

	const toggleVisibility = (code: string) => {
		// setVisibleSection(code)
	}
	const redrawLinks = () => {
		Object.keys(renderKey).forEach((sectionId) => {
			setRenderKey((prev) => ({
				...prev,
				[sectionId]: Math.random(),
			}))
		})
	}

	useEffect(() => {
		if (
			props?.swimlaneActivityListShowStatus &&
			props?.swimlaneWbsListShowStatus
		) {
			getSwimlanesByActivity({
				page: 1,
				page_size: 500,
				project: projectId,
			})
		}
	}, [
		props?.swimlaneActivityListShowStatus,
		props?.swimlaneWbsListShowStatus,
		projectId,
		getSwimlanesByActivity,
	])

	useEffect(() => {
		if (activityData?.results) {
			const activitiesList = activityData.results.map((activity: any) => ({
				id: activity.id,
				label: activity.actv_code_name,
				name: activity.actv_code_name,
			}))
			setSwimlaneActivityList(activitiesList)
		}
	}, [activityData])

	const getLinesFromCards = (cards: Item[]): Lines[] => {
		const cardLines: Lines[] = []
		cards?.forEach((card) => {
			card.lines.forEach((line) => {
				cardLines.push(line)
			})
		})
		return cardLines
	}

	useEffect(() => {
		if (
			!editable ||
			!projectId ||
			(!swimlaneNavState?.selected_wbs &&
				!swimlaneNavState?.selected_activity_code)
		) {
			return
		}
		updateStateApiTrigger({
			wbs_state: swimlaneNavState?.selected_wbs,
			activity_code_state: swimlaneNavState?.selected_activity_code,
			id: projectId?.toString() || "",
			start_date_state: datePivotToLoad,
		})
			.unwrap()
			.then((res) => {
				// State updated for viewing
			})
			.catch((err) => {
				console.log("err", err)
			})
	}, [
		swimlaneNavState,
		editable,
		projectId,
		datePivotToLoad,
		updateStateApiTrigger,
	])

	return (
		<>
			{isFetching && !editing && !props?.viewerReload && <AppLoader open />}
			<Box display={"flex"} justifyContent={"space-between"}>
				{props?.swimlaneActivityListShowStatus &&
					props?.swimlaneWbsListShowStatus && (
						<Box>
							<AppAutocompleteFilter
								disabled={isViewer}
								label={"Activities"}
								placeholder={"search_here"}
								options={swimlaneActivityList}
								value={activityFilter}
								onChange={(value: any) => {
									setActivityFilter(value)
									dispatch(
										updateSwimlaneNav({
											selected_wbs: swimlaneNavState?.selected_wbs,
											navType: "both",
											projectId: projectId ?? 0,
											selected_activity_code: value,
										}),
									)
								}}
							/>
						</Box>
					)}
				<Grid2 spacing={2} container alignItems="center">
					<Grid2 container>
						<Typography color="black">Show Relations</Typography>
						<CustomSwitch
							id="showRelations"
							checked={showRelations}
							onChange={(e) => {
								setShowRelations(e.target.checked)
								setRelationToggleEnabled(e.target.checked)
							}}
						/>
					</Grid2>
					<Box mb={1} mr={2}>
						<Button
							variant="contained"
							disabled={isViewer}
							onClick={handleBack}
							style={{ marginRight: "10px" }}
						>
							Back
						</Button>
						<Button
							variant="contained"
							disabled={isViewer}
							onClick={handleNext}
						>
							Next
						</Button>
					</Box>
				</Grid2>
			</Box>
			<div className="swimlane-calender-container">
				<Timeline
					key={0}
					groups={[]}
					items={[]}
					// keys={keys}
					itemTouchSendsClick={false}
					stackItems
					// showCursorLine
					canMove={editable}
					// canResize={editable ? "both" : false}
					canChangeGroup={false}
					sidebarWidth={0}
					rightSidebarWidth={0}
					minZoom={minZoom}
					maxZoom={maxZoom}
					traditionalZoom={true}
					dragSnap={1000 * 60 * 60 * 24} // 1 day
					useResizeHandle={true}
					visibleTimeStart={visibleSection.visibleTimeStart}
					visibleTimeEnd={visibleSection.visibleTimeEnd}
					onTimeChange={handleTimeChange}
					// verticalLineClassNamesForTime={}
				>
					<TimelineHeaders>
						<DateHeader
							style={{ backgroundColor: "#215B90", color: "white" }}
							unit="month"
							className="sticky"
						/>
						<DateHeader
							unit="day"
							style={{
								color: "white",
								minWidth: "50px",
							}}
							labelFormat="DD"
							// className="sticky"
						/>
					</TimelineHeaders>
				</Timeline>
				{sections &&
					Object.keys(sections).map((sectionId) => {
						const section = sections[sectionId]
						return (
							<Xwrapper key={sectionId}>
								<div
									className="expand"
									// onClick={() => {
									// 	toggleVisibility(sectionId)
									// }}
								>
									<Typography color="white">{section.name}</Typography>
									{/* <Svgs.ExpandIcon /> */}
								</div>
								{showRelations && (
									<div
										key={renderKey[sectionId]}
										style={{
											zIndex: -10,
											overflow: "hidden",
										}}
									>
										<LineConnect
											lines={lines && lines[sectionId] ? lines[sectionId] : []}
										/>
									</div>
								)}
								<Timeline
									key={sectionId}
									groups={section.groups}
									items={
										cards && cards[sectionId]?.length > 0
											? cards[sectionId]
											: []
									}
									// keys={keys}
									// sidebarContent={<div>Above The Left</div>}
									itemTouchSendsClick={false}
									stackItems
									itemHeightRatio={1}
									itemRenderer={CustomItemRenderer}
									// showCursorLine={true}
									canMove={editable}
									// canResize={editable ? "both" : false}
									canChangeGroup={false}
									sidebarWidth={0}
									rightSidebarWidth={0}
									minZoom={minZoom}
									maxZoom={maxZoom}
									visibleTimeStart={visibleSection.visibleTimeStart}
									visibleTimeEnd={visibleSection.visibleTimeEnd}
									onTimeChange={handleTimeChange}
									onItemMove={onItemMove}
									onItemResize={onItemResize}
									onItemDrag={onItemDrag}
									dragSnap={1000 * 60 * 60 * 24} // 1 day
								>
									<TimelineHeaders>
										<></>
									</TimelineHeaders>
								</Timeline>
							</Xwrapper>
						)
					})}
			</div>
		</>
	)
}

export default SwimlaneCalender
