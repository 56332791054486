type TAppEnv = "dev" | "prod"

export const APP_ENV: TAppEnv =
	window.location.hostname.includes("localhost") ||
	window.location.hostname.includes(
		"brg-pullpro-47675-staging.azurewebsites.net",
	)
		? "dev" // DEV
		: "prod" // PROD

let _API_SERVER_URL = ""

switch (APP_ENV as TAppEnv) {
	case "dev":
		_API_SERVER_URL = "https://brg-pullpro-47675-staging.azurewebsites.net/"
		break
	case "prod":
		_API_SERVER_URL = "https://brg-pullpro-47675.azurewebsites.net/"
		break
	default:
		_API_SERVER_URL = ""
		break
}

export const API_SERVER_URL = _API_SERVER_URL
export const API_VERSION_SUFFIX = "/api/v1"

export const API_ENDPOINTS = {
	// onboarding
	LOGIN: `${API_VERSION_SUFFIX}/login/`,
	LOGOUT: `${API_VERSION_SUFFIX}/logout/`,
	VERIFY_SIGNUP_CODE: `/invite/invitations/invite_link_verify/`,
	SIGNUP: `${API_VERSION_SUFFIX}/signup/`,
	FORGOT_PASSWORD: `${API_VERSION_SUFFIX}/forgot-password-request/`,
	LINK_EXPIRED: `${API_VERSION_SUFFIX}/forgot-password-link-verify/`,
	RESET_PASSWORD: `${API_VERSION_SUFFIX}/forgot-password-update/`,
	RESEND_INVITATION: `/invite/invitations/resend_invitation/`,
	INVITATION_LOGS: `/invite/invitation-logs/`,
	ACTIVATION_DEACTIVATION_LOGS: `${API_VERSION_SUFFIX}/users-activation/user_activation_logs/`,
	USER_PROFILE_UPDATE_LOGS: `${API_VERSION_SUFFIX}/profile/user_profile_logs/`,
	LOGIN_ATTEMPTS_LOGS: `${API_VERSION_SUFFIX}/login/login_attempts_logs/`,
	PASSWORD_RESET_LOGS: `${API_VERSION_SUFFIX}/password_reset_logs/`,
	ACTIVATE_DEACTIVATE_USER: (id: string) =>
		`${API_VERSION_SUFFIX}/users-activation/${id}/`,
	GET_ALL_CONFIG: "/api/v1/configuration-settings/",
	UPDATE_CONFIG: (id: string) => `/api/v1/configuration-settings/${id}/`,
	COMPANY: {
		COMPANIES: `/company/companies/`,
		ADD_COMPANY: `/company/companies/`,
		GET_COMPANY: (id: string) => `/company/companies/${id}/`,
		PROFILE_SETUP: `${API_VERSION_SUFFIX}/profile/update_profile/`,
		UPDATE_OTHER_PROFILE: (id: string) =>
			`${API_VERSION_SUFFIX}/profile/${id}/update_others_profile/`,
		GET_PROFILE: `${API_VERSION_SUFFIX}/profile/`,
		GET_COMPANY_USERS: `/company/company-users/`,
		CHANGE_STATUS: (id: string) => `/company/companies/${id}/change_status/`,
	},
	USERS: {
		CHANGE_STATUS: `${API_VERSION_SUFFIX}/users-activation/`,
		INVITE: "/invite/invitations/",
		USERS_ACTIVATION: `${API_VERSION_SUFFIX}/users-activation/`,
		LIMITED_ROLES: `${API_VERSION_SUFFIX}/user-roles/limited_roles/`,
		GET_PUBLIC_PROFILE: (id: string) =>
			`${API_VERSION_SUFFIX}/profile/${id}/view_user_profile/`,
	},
	// company
	GET_ALL_COMPANY: "/company/companies/",
	// projects
	PROJECTS: {
		PROJECTS: "/project/projects/",
		UPDATE_PROJECT: (id: string) => `/project/projects/${id}/`,
		UPDATE_PROJECT_CALENDAR: (id: string) =>
			`/project/calendar/${id}/update_selected_calendar/`,
		GET_ALL_PROJECTS: "/project/projects/",
		GET_PROJECT: (id: string) => `/project/projects/${id}/`,
		GET_CALENDAR_DETAILS: (id: string) => `/project/calendar/${id}/`,
		GET_ALL_CLIENTS: "/project/projects/get_clients/",
		GET_AVAILABLE_USERS_TO_ADD_FOR_PROJECT: (id: string) =>
			`/project/projects/${id}/get_company_members/`,
		ADD_MEMBER_TO_PROJECT: (id: string) =>
			`/project/projects/${id}/add_members/`,
		GET_ALL_PROJECTS_FOR_USER: "/project/projects/my_projects/",
		GET_PROJECT_MEMBERS: (id: string) =>
			`/project/projects/${id}/get_project_members/`,
		SEND_FEEDBACK: `/contact-us/feedback/`,
		GET_ALL_FEEDBACKS: `/contact-us/feedback/`,
		GET_FEEDBACK: (id: string) => `/contact-us/feedback/${id}/`,
		REPLY_TO_FEEDBACK: (id: string) =>
			`/contact-us/feedback/${id}/reply_feedback/`,
		FEEDBACK_RESOLVED: (id: string) =>
			`/contact-us/feedback/${id}/resolve_feedback/`,
		UPLOAD_XER_FILE: "/p6/xer-import/",
		GET_ALL_WBS: "/project/wbs/",
		GET_A_WBS: (id: string) => `/project/wbs/?project=${id}`,
		CREATE_ACTIVITY: "/activity/activities/",
		GET_ALL_ACTIVITIES: "/activity/activities/",
		GET_ACTIVITY: (id: string) => `/activity/activities/${id}/`,
		UPDATE_ACTIVITY: (id: string) => `/activity/activities/${id}/`,
		CREATE_SUB_ACTIVITY: "/activity/sub-activities/",
		GET_ALL_SUB_ACTIVITIES_BY_ACTIVITY: (id: string) =>
			`/activity/sub-activities/?activity=${id}`,
		GET_SUB_ACTIVITY: (id: string) => `/activity/sub-activities/${id}/`,
		GET_ALL_SUB_ACTIVITIES: "/activity/sub-activities/",
		UPDATE_SUB_ACTIVITY: (id: string) => `/activity/sub-activities/${id}/`,
		GET_PROJECT_TRADES: (id: string) => `/project/trades/?project=${id}`,
		SWIMLANES_LIST_BY_ACTIVITY_CODE: (id: string) =>
			`/project/swimlanes/swimlane_by_activity_code/?project=${id}`,
		SWIMLANES_LIST_BY_WBS: (id: string) =>
			`/project/swimlanes/swimlane_wbs/?project=${id}`,
		SWIMLANE_BASED_ACTIVITIES: `/activity/swimlane-based-activities/`,
		GET_SESSION_ID: (id: string) =>
			`/session/sessions/active_session/?project=${id}`,
		UPDATE_SWIMLANE_SETTINGS: (id: string) =>
			`/project/swimlane-settings/${id}/`,
		SESSION_LOGS: (id: string) => `/session/sessions/?project=${id}`,
		SESSION_DETAILS: (id: string) => `/session/session-logs/?session=${id}`,
		GET_SWIMLANE_STATE: (id: string) =>
			`/project/swimlane-state/?project=${id}`,
		UPDATE_SWIMLANE_STATE: (id: string) => `/project/swimlane-state/${id}/`,
		PROJECT_CALENDAR: (id: string) =>
			`/project/calendar/get_project_calendar/?project=${id}`,
	},
	ROLES: {
		GET_ALL_ROLES: `${API_VERSION_SUFFIX}/user-roles/`,
		GET_LIMITED_ROLES: `${API_VERSION_SUFFIX}/user-roles/limited_roles/`,
		UPDATE_USER_ROLE_FOR_PROJECT: `${API_VERSION_SUFFIX}/user-roles/change_user_role/`,
	},
	SESSION: {
		BEGIN_SESSION: `/session/sessions/`,
		END_SESSION: (id: string) => `/session/sessions/${id}/end_session/`,
		GET_SESSION: (id: string) => `/session/sessions/?project=${id}`,
	},
}
