import { Box, Grid2, Typography } from "@mui/material"
import "./Logs.scss"
import AppButton from "../../components/AppButton/AppButton"
import AppNavBar from "../../components/AppNavBar/AppNavBar"
import SearchInput from "../../components/AppSearchBox/AppSearchBox"
import CommonAutocomplete from "../../components/AutoCompleteBox/AutoCompleteBox"
import { useEffect, useState } from "react"
import DisplayTable, {
	TableHeader,
} from "../../components/DisplayTable/DisplayTable"
import {
	useLazyActivationDeactivationLogsQuery,
	useLazyInvitationLogsQuery,
	useLazyPasswordLogsQuery,
	useLazyUpdateProfileLogsQuery,
	useLazyUserLoginAttemptsLogsQuery,
} from "../../api/network/userApiService"
import { localStorage } from "../../utils/localstorage"
import AppLoader from "../../components/AppLoader/AppLoader"
import { useTranslation } from "react-i18next"
import {
	useGetAllCompaniesQuery,
	useGetCompanyUsersQuery,
} from "../../api/network/companyApiService"
import { sortData } from "../../constants/constants"
import { useDebounce } from "../../constants/constants"
import useAppPagination from "../../hooks/useAppPagination"
import { formatDateWithMonthName } from "../../utils/dateTime"
import { useNavigate } from "react-router-dom"
import { routeUrls } from "../../routes/routeUrls"
import { getTotalPages } from "../../utils"
import { set } from "date-fns"

const Logs = () => {
	const [selectedUser, setSelectedUser] = useState<string | null>(null)
	// const [pageRowsCount, setPageRowsCount] = useState(10)
	const [userId, setUserId] = useState<string | null>(null)
	const [currentUserId, setCurrentUserId] = useState<string | "">("")
	const [userRole, setUserRole] = useState<string | null>(null)
	const [listData, setListData] = useState<any>([])
	const [searchTerm, setSearchTerm] = useState("")
	const [currentPage, setCurrentPage] = useState<any>(1)
	const [activeLogType, setActiveLogType] = useState("Reset Password") // Active log type
	const [totalItems, setTotalItems] = useState(0) // Total items count
	const [companyUserId, setCompanyUserId] = useState("") // Company user id
	const [tableHeaders, setTableHeaders] = useState<TableHeader[]>([]) // Table headers
	const [tableColumns, setTableColumns] = useState<any[]>([]) // Table columns
	const [totalPageCount, setTotalPageCount] = useState(0) // Total page count
	const [pageSize, setPageSize] = useState()
	const [id, setId] = useState("1");

	const {
		pageRowsCount,
		// currentPage,
		// handlePageChange,
		handlePageCountChange,
	} = useAppPagination()

	const navigate = useNavigate()

	const { t } = useTranslation("translation", { keyPrefix: "logs" })
	const loginStatus = (status: boolean) => {
		if (status) {
			return "Logged In Successful"
		} else {
			return "Logged In Failed"
		}
	}
	const activationStatus = (status: boolean) => {
		if (status) {
			return "Activated"
		} else {
			return "Deactivated"
		}
	}
	const { data: { results: companiesData = [] } = {}, isFetching } =
		useGetAllCompaniesQuery(
			{
				page_size: 200,
				page: 1,
			},
			{ skip: userRole !== "Super Admin" },
		)
	const { data: users, refetch: refetchUsers } = useGetCompanyUsersQuery(
		{ id: companyUserId || userId || currentUserId },
		{ skip: companyUserId == '' },
	)
	useEffect(() => {
		if (activeLogType == "Reset Password") {
			setTableHeaders([
				{ name: "Log Entry", sortable: false },
				{ name: "User", sortable: false },
			])
			setTableColumns([
				(data: any) => formatDateWithMonthName(data.created_at),
				"user",
				"reset_by",
			])
		}
		else if (activeLogType == "Log In") {
			setTableHeaders([
				{ name: "Log Entry", sortable: false },
				{ name: "User", sortable: false },
				{ name: "Status", sortable: false },
			])
			setTableColumns([
				(data: any) => formatDateWithMonthName(data.timestamp),
				"user",
				(data: any) => loginStatus(data.is_successful),
			])
		}

		else if (
			activeLogType == "Deactivation"
		) {
			setTableHeaders([
				{ name: "Log Entry", sortable: false },
				{ name: "User", sortable: false },
				{ name: "Status", sortable: false },
				{ name: "Status Updated By", sortable: false },
			])
			setTableColumns([
				(data: any) => formatDateWithMonthName(data.timestamp),
				"user",
				(data: any) => activationStatus(data.status),
				"status_updated_by",
			])
		}
		else if (activeLogType == "Profile Update") {
			setTableHeaders([
				{ name: "Log Entry", sortable: false },
				{ name: "Updated by User", sortable: false },
			])
			setTableColumns([
				(data: any) => formatDateWithMonthName(data.timestamp),
				"user",
			])
		}
		else if (activeLogType == "Invitation") {
			setTableHeaders([
				{ name: "Log Entry", sortable: false },
				{ name: "Invited User", sortable: false },
				{ name: "Invitation Status", sortable: false },
				{ name: "Invited By", sortable: false },
			])
			setTableColumns([
				(data: any) => formatDateWithMonthName(data.timestamp),
				"invited_user_email",
				"invitation_status",
				"invited_by",
			])
		}
	}, [activeLogType])

	const [
		triggerPasswordLogsQuery,
		{ data: passwordLogs, isLoading: isPasswordLoading },
	] = useLazyPasswordLogsQuery()
	const [
		triggerProfileUpdateLogsQuery,
		{ data: updateLogs, isLoading: isUpdateLoading },
	] = useLazyUpdateProfileLogsQuery()
	const [
		triggerLoginAttemptsLogsQuery,
		{ data: loginAttemptsLogs, isLoading: isLoginAttemptsLoading },
	] = useLazyUserLoginAttemptsLogsQuery()
	const [
		triggerActivationsLogsQuery,
		{ data: activationLogs, isLoading: isActivationLoading },
	] = useLazyActivationDeactivationLogsQuery()
	const [
		triggerInvitationLogsQuery,
		{ data: invitationLogs, isLoading: isInvitationLoading },
	] = useLazyInvitationLogsQuery()
	const handleResetPasswordLogs = (id: any) => {
		triggerPasswordLogsQuery({
			id,
			page: currentPage,
			page_size: pageRowsCount,
		})
	}

	const handleFetchLogs = (
		label: string,
		pageRowsCount: number,
		currentPage: number,
		id: string,
	) => {
		setId(id);
		setActiveLogType(label) // Set the active log type
		switch (label) {
			case "Reset Password":
				triggerPasswordLogsQuery({
					id,
					page: currentPage,
					page_size: pageRowsCount,
				})
				break
			case "Log In":
				triggerLoginAttemptsLogsQuery({
					id,
					page: currentPage,
					page_size: pageRowsCount,
				})
				break
			case "Invitation":
				triggerInvitationLogsQuery({
					id,
					page: currentPage,
					page_size: pageRowsCount,
					search: searchTerm
				})
				break
			case "Deactivation":
				triggerActivationsLogsQuery({
					id,
					page: currentPage,
					page_size: pageRowsCount,
				})
				break
			case "Profile Update":
				triggerProfileUpdateLogsQuery({
					id,
					page: currentPage,
					page_size: pageRowsCount,
				})
				break
			default:
				break
		}
	}

	useEffect(() => {
		if (searchTerm !== null) {
			triggerInvitationLogsQuery({
				id,
				page: currentPage,
				page_size: pageRowsCount,
				search: searchTerm,
			});
		}
	}, [id, currentPage, pageRowsCount, searchTerm]);


	useEffect(() => {
		const logDataMap: { [key: string]: any } = {
			"Reset Password": passwordLogs,
			"Log In": loginAttemptsLogs,
			Invitation: invitationLogs,
			Deactivation: activationLogs,
			"Profile Update": updateLogs,
		}
		if (activeLogType && logDataMap[activeLogType]) {
			setTotalPageCount(logDataMap[activeLogType]?.count || 0) // Update total pages
			setListData(logDataMap[activeLogType]?.results || [])
			setTotalItems(logDataMap[activeLogType]?.total || 0) // Update total items
			setPageSize(logDataMap[activeLogType]?.page_size || 10)
		}
	}, [
		passwordLogs,
		loginAttemptsLogs,
		invitationLogs,
		activationLogs,
		updateLogs,
		activeLogType,
	])
	useEffect(() => {
		if (companyUserId && companyUserId !== userId) {
			refetchUsers() // Fetch users for the selected company
		}
	}, [refetchUsers, companyUserId])

	const handleClickLabel = (label: string) => {
		handleFetchLogs(
			label,
			pageRowsCount,
			currentPage,
			companyUserId || userId || companyUserId,
		)
	}
	// const totalPages = Math.ceil(totalPageCount / pageRowsCount) // Calculate total pages
	const onCompanyDetailClick = (id: string) => {
		console.log("Clicked Company ID:", id)
	}

	const handleRowChange = (rows: any) => {
		// setPageRowsCount(rows) // Update the page rows count
		handleFetchLogs(
			activeLogType,
			rows,
			currentPage,
			companyUserId || userId || currentUserId,
		) // Fetch logs for the new page
	}

	const handlePageChange = (page: number) => {
		setCurrentPage(page)
		handleFetchLogs(
			activeLogType,
			pageRowsCount,
			page,
			companyUserId || userId || currentUserId,
		) // Fetch logs for the new page
	}

	const handlePageCount = (rows: number) => {
		handlePageCountChange(rows)
		console.log("Rows:", rows);
		pageRowsCount // Update the page rows count
		handleFetchLogs(
			activeLogType,
			rows,
			currentPage,
			companyUserId || userId || currentUserId,
		) // Fetch logs for the new page
	}

	const handleCompanyChange = (id: any, value: string | null | { id: any }) => {
		setSelectedUser(value as string | null)
		setCompanyUserId(id)
	}
	const handleUserChange = (id: any, value: string | null | { id: any }) => {
		setSelectedUser(value as string | null)
		setCompanyUserId('')
		setUserId(id)
	}
	const debouncedHandleSearchChange = useDebounce((value: string) => {
		setSearchTerm(value);
	}, 500);

	const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		debouncedHandleSearchChange(event.target.value)
	}
	useEffect(() => {
		if (activeLogType !== "Invitation") {
			setSearchTerm('');
		}
	}, [activeLogType]);

	const LogsOptions = [
		{ value: "Reset Password", label: "Reset Password" },
		{ value: "Log In", label: "Log In" },
		{ value: "Invitation", label: "Invitation" },
		{ value: "Deactivation", label: "Deactivation" },
		{ value: "Profile Update", label: "Profile Update" },
	]
	const companyList = companiesData.map((company) => ({
		label: company.name,
		id: company.id,
	}))

	const userList = users?.results.map((user: any) => ({
		label: user.name,
		id: user.id,
	})) || []

	const fetchUserData = async () => {
		try {
			const userData = await localStorage.getUserData()
			if (userData) {
				const jsonUserData = JSON.stringify(userData)
				console.log("User Data:", jsonUserData)
				setUserRole(userData.user.user_role?.role)
				if (userData.user.user_role?.role === 'Company Admin' && userData.user.company_id != null) {
					setUserId(userData.user.company_id.toString())
					setCompanyUserId(userData.user.company_id.toString())
					setCurrentUserId(userData.user.company_id.toString())
					handleResetPasswordLogs(userData.user.company_id.toString())
				}
				else {
					setUserId(userData.user.id.toString())
					setCurrentUserId(userData.user.id.toString())
					handleResetPasswordLogs(userData.user.id.toString())
				}
			} else {
				console.log("No user data found.")
			}
		} catch (error) {
			console.error("Error fetching user data:", error)
		}
	}
	const handleFetchLogsDetails = () => {
		handleFetchLogs(
			activeLogType,
			pageRowsCount,
			currentPage,
			companyUserId || userId || currentUserId
		);
	};

	const handleSortListData = (sortType: boolean) => {
		console.log("Sort Type:", sortType)
		setListData(sortData(listData, tableColumns[0], !sortType))
	}

	useEffect(() => {
		fetchUserData()
	}, [])

	// Ensures the user is redirected to the dashboard when the back button is clicked
	useEffect(() => {
		navigate(window.location.pathname, { replace: true });
		const handleBackNavigation = () => {
			navigate(routeUrls.dashboard, { replace: true });
		};
		window.history.pushState(null, "", window.location.href);
		window.addEventListener("popstate", handleBackNavigation);

		return () => {
			window.removeEventListener("popstate", handleBackNavigation);
		};
	}, [navigate]);

	return (
		<Box className="logs-container" display={"flex"}>
			<Box className="logs-content" flexGrow={1} marginLeft={4}>
				<Grid2 className="title">{t("logs")}</Grid2>
				{activeLogType === "Invitation" ? (
					<Box className="search-box-input" sx={{ marginBottom: 2.1 }}>
						<Typography className={"search-label"} width={'32%'}>{t("search")}</Typography>
						<Box sx={{ width: "89.5%" }}>
							<SearchInput
								value={searchTerm}
								onChange={handleSearchChange}
								placeholder={t("search_text")}
								fullWidth={true}
								height="45px"
							/>
						</Box>
					</Box>
				) : (
					<Box className="autocomplete-search-box">
						<Box display={"flex"} >
							{userRole === "Super Admin" && (
								<Grid2 className="title2" width={'32%'}>{t("company")}</Grid2>
							)}
							<Grid2 className="title2">{t("user")}</Grid2>
						</Box>
						<Box display={"flex"} gap={2} ml={'-5px'}>
							{userRole === "Super Admin" && (
								<CommonAutocomplete
									options={companyList || []}
									label="Company"
									onChange={handleCompanyChange}
								/>
							)}
							<CommonAutocomplete
								options={userList || []}
								label="User"
								onChange={handleUserChange}
							/>
							<AppButton
								type="button"
								btnSize="medium"
								sx={{ width: 150, marginTop: 1 }}
								onClick={handleFetchLogsDetails} // Call handleSubmit
							>
								{t("fetch_button")}
							</AppButton>
						</Box>
					</Box>
				)}
				<Box width={"90%"} marginTop={1}>
					<AppNavBar
						options={LogsOptions.map((option) => option.value)}
						handleClickLabel={handleClickLabel}
						defaultSelected={LogsOptions[0].value}
						marginBottom={true}
					/>
				</Box>
				<Box>
					<Box style={{ backgroundColor: "#F1F5F7" }}>
						<DisplayTable
							isFetching={
								isPasswordLoading ||
								isUpdateLoading ||
								isLoginAttemptsLoading ||
								isActivationLoading ||
								isInvitationLoading
							}
							tableHeaders={tableHeaders}
							tableColumns={tableColumns}
							results={listData}
							onRowClick={onCompanyDetailClick}
							pageRowsCount={pageRowsCount}
							onPageCountChange={handlePageCount}
							totalPages={getTotalPages(totalPageCount, pageSize)}
							currentPage={currentPage}
							totalPageCount={totalPageCount || 0}
							onPageChange={handlePageChange}
							handleSortListData={handleSortListData}
						// handleViewClick={onCompanyDetailClick}
						/>
					</Box>
				</Box>
			</Box>
		</Box>

	)
}

export default Logs
