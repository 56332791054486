import { Box, Grid2, LinearProgress, Typography } from "@mui/material"
import { Drawer } from "../../../components/AppSidebar/AppSidebar"
import SwimlaneCalender from "./../SwimlaneCalender"
import SwimlaneLists from "./../SwimlaneLists"
import { Svgs } from "../../../assets/svgs"
import AppButton from "../../../components/AppButton/AppButton"
import { useEffect, useState } from "react"
import { formatDateWithMonthName } from "../../../utils/dateTime"
import { useNavigate, useParams } from "react-router-dom"
import {
	downloadFile,
	useEndSessionMutation,
	useGetProjectDetailsQuery,
	useGetSessionIdQuery,
	useGetSwimlaneStateByProjectIdQuery,
} from "../../../api/network/projectApiService"
import { AddOutlined } from "@mui/icons-material"
import { routeUrls } from "../../../routes/routeUrls"
import { toast } from "react-toastify"
import { getProjectStatusColor } from "../../../utils"
import useUserRole from "../../../hooks/useUserRole"
import { ProjectUserRole, UserRole } from "../../../api/types/Auth"
import { SwimlaneNavState } from "../../../api/types/Project"
import { SWIMLANE_STATE_RESPONSE } from "../../../api/types/Swimlane"

const ViewerSwimlane = () => {
	const param = useParams()
	const [reloadCalendarTrigger, setReloadCalendarTrigger] = useState<
		number | null
	>(null)
	const { id: projectId, sessionId } = param
	const [swimlaneState, setSwimlaneState] = useState<
		SWIMLANE_STATE_RESPONSE | undefined
	>()
	const {
		data: projectData,
		isFetching,
		isError,
		error,
		refetch,
		isLoading,
	} = useGetProjectDetailsQuery(param?.id?.toString() ?? "")

	// const {
	// 	data: sessionDetails,
	// 	isLoading: isSessionLoading,
	// 	refetch: refetchSession,
	// 	error: sessionError,
	// } = useGetSessionIdQuery(projectData?.id)

	const {
		data: swimlaneStateData,
		isLoading: isStateLoading,
		refetch: refetchState,
		error: stateError,
		fulfilledTimeStamp,
	} = useGetSwimlaneStateByProjectIdQuery(
		{ project: projectData?.id?.toString() || "" },
		{
			skip: !projectData?.id,
			pollingInterval: 5000, // 5 seconds
		},
	)

	useEffect(() => {
		if (swimlaneState) {
			if (
				swimlaneStateData?.wbs_state !== swimlaneState.wbs_state ||
				swimlaneStateData?.activity_code_state !==
					swimlaneState.activity_code_state
			) {
				setSwimlaneState(swimlaneStateData)
			} else {
				setReloadCalendarTrigger(Math.random())
			}
		} else {
			setSwimlaneState(swimlaneStateData)
		}
	}, [fulfilledTimeStamp])

	const navigate = useNavigate()

	const handleBackClick = () => {
		navigate(routeUrls.projects.viewAndEdit(projectId), {
			state: { projectId },
		})
	}

	return (
		<Box display={"flex"}>
			<Box>
				<Drawer variant="permanent" open={true}>
					<Box
						display="flex"
						flexDirection="column"
						padding="14px 12px 12px 12px"
					>
						<Box
							display="flex"
							flex={1}
							justifyContent="space-between"
							alignItems={"center"}
						>
							<Svgs.SidebarLogo />
						</Box>
						<Box display={"flex"} gap={2} mt={2}>
							<Typography>
								<Svgs.BackArrow onClick={handleBackClick} />
							</Typography>
							<Typography
								style={{
									color: "#fff",
									fontSize: "17px",
									fontWeight: "700",
									marginTop: "8px",
								}}
							>
								{projectData?.name}
							</Typography>
						</Box>
						<Box
							style={{
								color: "#fff",
								fontSize: "14px",
								fontWeight: "700",
							}}
						>
							<Grid2 mb={2}>
								{`Progress`}
								<span style={{ color: "#54AE2A" }}>{` (${80}%)`}</span>{" "}
							</Grid2>
							<LinearProgress
								variant="determinate"
								value={80}
								color="success"
							/>
						</Box>
						<Box ml={-1.5} mt={1}>
							<AppButton
								variant="text"
								btnSize="medium"
								sx={{
									color: getProjectStatusColor(projectData?.status || ""),
								}}
								startIcon={
									<Svgs.ProjectStatusLogo
										color={getProjectStatusColor(projectData?.status || "")}
									/>
								}
							>
								{projectData?.status}
							</AppButton>
						</Box>
					</Box>
					<SwimlaneLists
						swimlaneWbsListShowStatus={projectData?.wbs_code_swimlane_enabled}
						swimlaneActivityListShowStatus={
							projectData?.activity_code_swimlane_enabled
						}
						projectId={param?.id ? parseInt(param?.id) : null}
						clickable={false}
						viewerActivityCode={swimlaneStateData?.activity_code_state?.toString()}
						viewerWbsCode={swimlaneStateData?.wbs_state?.toString()}
					/>
				</Drawer>
			</Box>
			<Box flex={1} padding={2}>
				<Box padding={2}>
					{/* <Box>
						<Grid2 display={"flex"} justifyContent={"space-between"} gap={1}>
							<Typography fontWeight={700} fontSize={24}>
								{sessionDetails?.session_name
									? sessionDetails?.session_name
									: ""}
							</Typography>
						</Grid2>
						<Typography fontWeight={500} fontSize={14}>
							{isSessionLoading
								? "Loading..."
								: sessionDetails === undefined || sessionDetails === null
									? "No Active Session"
									: sessionDetails.is_active
										? `${sessionDetails.session_id}` +
											(sessionDetails.created_at
												? ` ${formatDateWithMonthName(sessionDetails.created_at as string)}`
												: "") +
											(sessionDetails.end_date
												? ` - ${formatDateWithMonthName(sessionDetails.end_date as string)}`
												: "")
										: "No Active Session"}
						</Typography>
					</Box> */}
				</Box>
				<Box mt={4}>
					<SwimlaneCalender
						swimlaneWbsListShowStatus={projectData?.wbs_code_swimlane_enabled}
						swimlaneActivityListShowStatus={
							projectData?.activity_code_swimlane_enabled
						}
						session={sessionId}
						projectId={projectData?.id}
						editable={false}
						projectData={projectData}
						viewerReload={reloadCalendarTrigger}
					/>
				</Box>
			</Box>
		</Box>
	)
}

export default ViewerSwimlane
