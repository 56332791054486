import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { API_ENDPOINTS, API_SERVER_URL } from "./endpoints"
import { API_METHODS } from "../../constants/constants"
import { handleAuthError } from "./errorHandler"
import { getUserData } from "../../utils/localstorage"
import {
	ActivityDetail,
	ClientsResponse,
	CreateActivityPayload,
	CreateProjectPayload,
	GetAllActivitiesPayload,
	GetAllWbsPayload,
	GetProjectListPayload,
	GetProjectMembersPayload,
	ProjectDetails,
	SwimlaneWBSListItem,
	TAddTeamMemberProjectPayload,
	TProjectMember,
	UpdateActivityPayload,
	UpdateProjectPayload,
	UpdateSwimlanePayload,
	UserProjectDetail,
	WBSDetail,
} from "../types/Project"
import { ListingResponse } from "../types/Listing"
import { UpdateUserRolePayload, UpdateUserRoleResopnse } from "../types/Roles"
import {
	SWIMLANE_STATE_RESPONSE,
	TSWIMLANE_ACTIVITY_PARAMS,
} from "../types/Swimlane"

// Fetch request to download xer file
export const downloadFile = (
	xerFileUrl: string,
	name: string,
	onSuccess?: () => void,
	onError?: () => void,
) => {
	fetch(xerFileUrl, {
		method: API_METHODS.GET,
		headers: {
			"Content-Type": "application/octet-stream",
		},
	})
		.then((resp) => {
			console.log(resp)
			return resp.blob()
		})
		.then((blob) => {
			const url = window.URL.createObjectURL(blob)
			const a = document.createElement("a")
			a.style.display = "none"
			a.href = url
			// the filename you want
			a.download = name
			document.body.appendChild(a)
			a.click()
			window.URL.revokeObjectURL(url)
			onSuccess && onSuccess()
		})
		.catch(() => {
			console.log("error")
			onError && onError()
		})
}

export enum projectApiTags {
	PROJECT_CREATED = "PROJECT_CREATED",
	PROJECT_UPDATED = "PROJECT_UPDATED",
	TEAM_MEMBER_ADDED = "TEAM_MEMBER_ADDED",
	TEAM_MEMBER_UPDATED = "TEAM_MEMBER_UPDATED",
	ACTIVITY_CREATED = "ACTIVITY_CREATED",
	ACTIVITY_UPDATED = "ACTIVITY_UPDATED",
	SUB_ACTIVITY_CREATED = "SUB_ACTIVITY_CREATED",
	SUB_ACTIVITY_UPDATED = "SUB_ACTIVITY_UPDATED",
	END_SESSION = "END_SESSION",
	BEGIN_SESSION = "BEGIN_SESSION",
}

export const ProjectAPIs = createApi({
	reducerPath: "ProjectAPI",
	tagTypes: [
		projectApiTags.PROJECT_CREATED,
		projectApiTags.PROJECT_UPDATED,
		projectApiTags.TEAM_MEMBER_ADDED,
		projectApiTags.TEAM_MEMBER_UPDATED,
		projectApiTags.ACTIVITY_CREATED,
		projectApiTags.ACTIVITY_UPDATED,
		projectApiTags.SUB_ACTIVITY_CREATED,
		projectApiTags.SUB_ACTIVITY_UPDATED,
		projectApiTags.END_SESSION,
		projectApiTags.BEGIN_SESSION,
	],
	baseQuery: fetchBaseQuery({
		baseUrl: API_SERVER_URL,
		prepareHeaders: async (headers) => {
			headers.set("Content-Type", "application/json")
			const userData = await getUserData()
			headers.set("Authorization", `Token ${userData?.token}`)
			return headers
		},
	}),
	endpoints: (builder) => ({
		getAllClients: builder.query<ClientsResponse, null>({
			query: () => {
				return {
					url: API_ENDPOINTS.PROJECTS.GET_ALL_CLIENTS,
					method: API_METHODS.GET,
				}
			},
			providesTags: [projectApiTags.PROJECT_CREATED],
			transformErrorResponse(baseQueryReturnValue) {
				handleAuthError(baseQueryReturnValue)
				return baseQueryReturnValue
			},
			transformResponse: async (data: ClientsResponse) => {
				return data
			},
		}),
		createProject: builder.mutation<ProjectDetails, CreateProjectPayload>({
			query: (params: CreateProjectPayload) => {
				return {
					url: API_ENDPOINTS.PROJECTS.PROJECTS,
					method: API_METHODS.POST,
					body: params,
				}
			},
			invalidatesTags: [projectApiTags.PROJECT_CREATED],
			transformErrorResponse(baseQueryReturnValue) {
				// handleAuthError(baseQueryReturnValue)
				return baseQueryReturnValue
			},
			transformResponse: async (data: ProjectDetails) => {
				return data
			},
		}),
		updateProject: builder.mutation<ProjectDetails, UpdateProjectPayload>({
			query: ({ id, ...params }: UpdateProjectPayload) => {
				return {
					url: API_ENDPOINTS.PROJECTS.UPDATE_PROJECT(id.toString()),
					method: API_METHODS.PATCH,
					body: params,
				}
			},
			invalidatesTags: [projectApiTags.PROJECT_UPDATED],
			transformErrorResponse(baseQueryReturnValue) {
				handleAuthError(baseQueryReturnValue)
				return baseQueryReturnValue
			},
			transformResponse: async (data: ProjectDetails) => {
				return data
			},
		}),
		updateProjectCalendar: builder.mutation<
			{ message: string },
			{ id: string }
		>({
			query: ({ id }) => ({
				url: API_ENDPOINTS.PROJECTS.UPDATE_PROJECT_CALENDAR(id),
				method: API_METHODS.PATCH,
			}),
			invalidatesTags: [projectApiTags.PROJECT_UPDATED],
			transformErrorResponse(baseQueryReturnValue) {
				handleAuthError(baseQueryReturnValue)
				return baseQueryReturnValue
			},
			transformResponse: async (response: { message: string }) => {
				return response
			},
		}),
		getAllProjects: builder.query<
			ListingResponse<ProjectDetails[]>,
			GetProjectListPayload
		>({
			query: (params?: GetProjectListPayload) => {
				return {
					url: API_ENDPOINTS.PROJECTS.GET_ALL_PROJECTS,
					method: API_METHODS.GET,
					params: params,
				}
			},
			providesTags: [
				projectApiTags.PROJECT_CREATED,
				projectApiTags.PROJECT_UPDATED,
				projectApiTags.TEAM_MEMBER_ADDED,
				projectApiTags.TEAM_MEMBER_UPDATED,
			],
			transformErrorResponse(baseQueryReturnValue) {
				handleAuthError(baseQueryReturnValue)
				return baseQueryReturnValue
			},
			transformResponse: async (data: ListingResponse<ProjectDetails[]>) => {
				return data
			},
		}),
		getHolidayCalendarDetails: builder.query<any, string>({
			query: (id) => {
				return {
					url: API_ENDPOINTS.PROJECTS.GET_CALENDAR_DETAILS(id.toString()),
					method: API_METHODS.GET,
				}
			},
			transformErrorResponse(baseQueryReturnValue) {
				handleAuthError(baseQueryReturnValue)
				return baseQueryReturnValue
			},
			transformResponse: async (data: any) => {
				return data
			},
		}),
		getProjectDetails: builder.query<ProjectDetails, string>({
			query: (id) => {
				return {
					url: API_ENDPOINTS.PROJECTS.GET_PROJECT(id.toString()),
					method: API_METHODS.GET,
				}
			},
			providesTags: [
				projectApiTags.PROJECT_UPDATED,
				projectApiTags.TEAM_MEMBER_ADDED,
				projectApiTags.TEAM_MEMBER_UPDATED,
				projectApiTags.END_SESSION,
				projectApiTags.BEGIN_SESSION,
			],
			transformErrorResponse(baseQueryReturnValue) {
				handleAuthError(baseQueryReturnValue)
				return baseQueryReturnValue
			},
			transformResponse: async (data: ProjectDetails) => {
				return data
			},
		}),
		getAvailableUsersToAddForProject: builder.query<
			ListingResponse<[]>,
			string
		>({
			query: (id: string) => {
				return {
					url: API_ENDPOINTS.PROJECTS.GET_AVAILABLE_USERS_TO_ADD_FOR_PROJECT(
						id,
					),
					method: API_METHODS.GET,
				}
			},
			providesTags: [projectApiTags.TEAM_MEMBER_ADDED],
			transformErrorResponse(baseQueryReturnValue) {
				handleAuthError(baseQueryReturnValue)
				return baseQueryReturnValue
			},
			transformResponse: async (data: ListingResponse<[]>) => {
				return data
			},
		}),
		addMemberToProject: builder.mutation<
			ProjectDetails,
			TAddTeamMemberProjectPayload
		>({
			query: ({ id, ...params }: TAddTeamMemberProjectPayload) => {
				return {
					url: API_ENDPOINTS.PROJECTS.ADD_MEMBER_TO_PROJECT(id),
					method: API_METHODS.PATCH,
					body: params,
				}
			},
			invalidatesTags: [projectApiTags.TEAM_MEMBER_ADDED],
			transformErrorResponse(baseQueryReturnValue) {
				handleAuthError(baseQueryReturnValue)
				return baseQueryReturnValue
			},
			transformResponse: async (data: ProjectDetails) => {
				return data
			},
		}),
		updateUserRoleForProject: builder.mutation<
			UpdateUserRoleResopnse,
			UpdateUserRolePayload
		>({
			query: (params: UpdateUserRolePayload) => {
				return {
					url: API_ENDPOINTS.ROLES.UPDATE_USER_ROLE_FOR_PROJECT,
					method: API_METHODS.PATCH,
					body: params,
				}
			},
			invalidatesTags: [projectApiTags.TEAM_MEMBER_UPDATED],
			transformErrorResponse(baseQueryReturnValue) {
				handleAuthError(baseQueryReturnValue)
				return baseQueryReturnValue
			},
			transformResponse: async (data: UpdateUserRoleResopnse) => {
				return data
			},
		}),
		getAllProjectsForUser: builder.query<
			ListingResponse<UserProjectDetail[]>,
			void
		>({
			query: () => {
				return {
					url: API_ENDPOINTS.PROJECTS.GET_ALL_PROJECTS_FOR_USER,
					method: API_METHODS.GET,
				}
			},
			transformErrorResponse(baseQueryReturnValue) {
				handleAuthError(baseQueryReturnValue)
				return baseQueryReturnValue
			},
			transformResponse: async (data: ListingResponse<UserProjectDetail[]>) => {
				return data
			},
		}),
		getProjectMembers: builder.query<
			ListingResponse<TProjectMember[]>,
			GetProjectMembersPayload
		>({
			query: ({ id, ...params }) => {
				return {
					url: API_ENDPOINTS.PROJECTS.GET_PROJECT_MEMBERS(id.toString()),
					method: API_METHODS.GET,
					params,
				}
			},
			transformErrorResponse(baseQueryReturnValue) {
				handleAuthError(baseQueryReturnValue)
				return baseQueryReturnValue
			},
			transformResponse: async (data: ListingResponse<TProjectMember[]>) => {
				return data
			},
		}),
		sendFeedback: builder.mutation<void, any>({
			query: (params) => {
				return {
					url: API_ENDPOINTS.PROJECTS.SEND_FEEDBACK,
					method: API_METHODS.POST,
					body: params,
				}
			},
			transformErrorResponse(baseQueryReturnValue) {
				handleAuthError(baseQueryReturnValue)
				return baseQueryReturnValue
			},
		}),
		getAllFeedbacks: builder.query<any, any>({
			query: (params) => {
				return {
					url: API_ENDPOINTS.PROJECTS.GET_ALL_FEEDBACKS,
					method: API_METHODS.GET,
					params,
				}
			},
			transformErrorResponse(baseQueryReturnValue) {
				handleAuthError(baseQueryReturnValue)
				return baseQueryReturnValue
			},
		}),
		getFeedbackById: builder.query<any, string>({
			query: (id) => {
				return {
					url: API_ENDPOINTS.PROJECTS.GET_FEEDBACK(id),
					method: API_METHODS.GET,
				}
			},
			transformErrorResponse(baseQueryReturnValue) {
				handleAuthError(baseQueryReturnValue)
				return baseQueryReturnValue
			},
		}),
		replyToFeedback: builder.mutation<void, any>({
			query: (params) => {
				return {
					url: API_ENDPOINTS.PROJECTS.REPLY_TO_FEEDBACK(params.id),
					method: API_METHODS.POST,
					body: params,
				}
			},
			transformErrorResponse(baseQueryReturnValue) {
				handleAuthError(baseQueryReturnValue)
				return baseQueryReturnValue
			},
		}),
		resolvedFeedback: builder.mutation<void, any>({
			query: (params) => {
				return {
					url: API_ENDPOINTS.PROJECTS.FEEDBACK_RESOLVED(params.id),
					method: API_METHODS.PATCH,
					body: params,
				}
			},
			transformErrorResponse(baseQueryReturnValue) {
				handleAuthError(baseQueryReturnValue)
				return baseQueryReturnValue
			},
		}),
		getAllWbs: builder.query<ListingResponse<WBSDetail[]>, GetAllWbsPayload>({
			query: () => {
				return {
					url: API_ENDPOINTS.PROJECTS.GET_ALL_WBS,
					method: API_METHODS.GET,
				}
			},
			transformErrorResponse(baseQueryReturnValue) {
				handleAuthError(baseQueryReturnValue)
				return baseQueryReturnValue
			},
			transformResponse: async (data: ListingResponse<WBSDetail[]>) => {
				return data
			},
		}),
		getAWbs: builder.query<ListingResponse<WBSDetail[]>, any>({
			query: (params) => {
				return {
					url: API_ENDPOINTS.PROJECTS.GET_A_WBS(params.project),
					method: API_METHODS.GET,
				}
			},
			transformErrorResponse(baseQueryReturnValue) {
				handleAuthError(baseQueryReturnValue)
				return baseQueryReturnValue
			},
			transformResponse: async (data: ListingResponse<WBSDetail[]>) => {
				return data
			},
		}),
		createActivity: builder.mutation<any, CreateActivityPayload>({
			query: (params) => {
				return {
					url: API_ENDPOINTS.PROJECTS.CREATE_ACTIVITY,
					method: API_METHODS.POST,
					body: params,
				}
			},
			invalidatesTags: [projectApiTags.ACTIVITY_CREATED],
			transformErrorResponse(baseQueryReturnValue) {
				handleAuthError(baseQueryReturnValue)
				return baseQueryReturnValue
			},
			transformResponse: async (data: any) => {
				return data
			},
		}),
		getAllActivities: builder.query<
			ListingResponse<ActivityDetail[]>,
			GetAllActivitiesPayload
		>({
			query: (params) => {
				return {
					url: API_ENDPOINTS.PROJECTS.GET_ALL_ACTIVITIES,
					method: API_METHODS.GET,
					params,
				}
			},
			providesTags: [
				projectApiTags.ACTIVITY_CREATED,
				projectApiTags.ACTIVITY_UPDATED,
			],
			transformErrorResponse(baseQueryReturnValue) {
				handleAuthError(baseQueryReturnValue)
				return baseQueryReturnValue
			},
			transformResponse: async (data: ListingResponse<ActivityDetail[]>) => {
				return data
			},
		}),
		getActivityById: builder.query<ActivityDetail, string>({
			query: (id) => {
				return {
					url: API_ENDPOINTS.PROJECTS.GET_ACTIVITY(id),
					method: API_METHODS.GET,
				}
			},
			providesTags: [
				projectApiTags.ACTIVITY_UPDATED,
				projectApiTags.SUB_ACTIVITY_CREATED,
				projectApiTags.SUB_ACTIVITY_UPDATED,
			],
			transformErrorResponse(baseQueryReturnValue) {
				handleAuthError(baseQueryReturnValue)
				return baseQueryReturnValue
			},
			transformResponse: async (data: ActivityDetail) => {
				return data
			},
		}),
		updateActivity: builder.mutation<ActivityDetail, UpdateActivityPayload>({
			query: ({ id, ...params }) => {
				return {
					url: API_ENDPOINTS.PROJECTS.UPDATE_ACTIVITY(id),
					method: API_METHODS.PATCH,
					body: params,
				}
			},
			invalidatesTags: [projectApiTags.ACTIVITY_UPDATED],
			transformErrorResponse(baseQueryReturnValue) {
				handleAuthError(baseQueryReturnValue)
				return baseQueryReturnValue
			},
			transformResponse: async (data: ActivityDetail) => {
				return data
			},
		}),
		createSubActivity: builder.mutation<any, any>({
			query: (params) => {
				return {
					url: API_ENDPOINTS.PROJECTS.CREATE_SUB_ACTIVITY,
					method: API_METHODS.POST,
					body: params,
				}
			},
			invalidatesTags: [projectApiTags.SUB_ACTIVITY_CREATED],
			transformErrorResponse(baseQueryReturnValue) {
				handleAuthError(baseQueryReturnValue)
				return baseQueryReturnValue
			},
			transformResponse: async (data: any) => {
				return data
			},
		}),
		getAllSubActivitiesByActivity: builder.query<any, string>({
			query: (id) => {
				return {
					url: API_ENDPOINTS.PROJECTS.GET_ALL_SUB_ACTIVITIES_BY_ACTIVITY(id),
					method: API_METHODS.GET,
				}
			},
			transformErrorResponse(baseQueryReturnValue) {
				handleAuthError(baseQueryReturnValue)
				return baseQueryReturnValue
			},
		}),
		getSubActivity: builder.query<any, string>({
			query: (id) => {
				return {
					url: API_ENDPOINTS.PROJECTS.GET_SUB_ACTIVITY(id),
					method: API_METHODS.GET,
				}
			},
			transformErrorResponse(baseQueryReturnValue) {
				handleAuthError(baseQueryReturnValue)
				return baseQueryReturnValue
			},
		}),
		getAllSubActivitiesList: builder.query<any, any>({
			query: (params) => {
				return {
					url: API_ENDPOINTS.PROJECTS.GET_ALL_SUB_ACTIVITIES,
					method: API_METHODS.GET,
					params,
				}
			},
			providesTags: [projectApiTags.SUB_ACTIVITY_UPDATED],
			transformErrorResponse(baseQueryReturnValue) {
				handleAuthError(baseQueryReturnValue)
				return baseQueryReturnValue
			},
		}),
		updateSubActivity: builder.mutation<any, any>({
			query: (params) => {
				return {
					url: API_ENDPOINTS.PROJECTS.UPDATE_SUB_ACTIVITY(params.id),
					method: API_METHODS.PATCH,
					body: params,
				}
			},
			invalidatesTags: [projectApiTags.SUB_ACTIVITY_UPDATED],
			transformErrorResponse(baseQueryReturnValue) {
				handleAuthError(baseQueryReturnValue)
				return baseQueryReturnValue
			},
		}),
		getSwimlanesListByActivityCode: builder.query<any, any>({
			query: (params) => {
				return {
					url: API_ENDPOINTS.PROJECTS.SWIMLANES_LIST_BY_ACTIVITY_CODE(
						params.project,
					),
					method: API_METHODS.GET,
					params: {
						page: params.page,
						page_size: params.page_size,
					},
				}
			},
			transformErrorResponse(baseQueryReturnValue) {
				handleAuthError(baseQueryReturnValue)
				return baseQueryReturnValue
			},
		}),
		getSwimlanesListByWbs: builder.query<
			ListingResponse<SwimlaneWBSListItem[]>,
			any
		>({
			query: (params) => {
				return {
					url: API_ENDPOINTS.PROJECTS.SWIMLANES_LIST_BY_WBS(params.project),
					method: API_METHODS.GET,
				}
			},
			transformErrorResponse(baseQueryReturnValue) {
				handleAuthError(baseQueryReturnValue)
				return baseQueryReturnValue
			},
		}),
		getSwimlaneBasedActivities: builder.query<any, TSWIMLANE_ACTIVITY_PARAMS>({
			query: (params: TSWIMLANE_ACTIVITY_PARAMS) => {
				return {
					url: API_ENDPOINTS.PROJECTS.SWIMLANE_BASED_ACTIVITIES,
					method: API_METHODS.GET,
					params,
				}
			},
			providesTags: [
				projectApiTags.ACTIVITY_UPDATED,
				projectApiTags.BEGIN_SESSION,
			],
			transformErrorResponse(baseQueryReturnValue) {
				// handleAuthError(baseQueryReturnValue)
				return baseQueryReturnValue
			},
		}),
		getAllConfigurations: builder.query<any, any>({
			query: () => {
				return {
					url: API_ENDPOINTS.GET_ALL_CONFIG,
					method: API_METHODS.GET,
				}
			},
			transformErrorResponse(baseQueryReturnValue) {
				handleAuthError(baseQueryReturnValue)
				return baseQueryReturnValue
			},
		}),
		updateConfiguration: builder.mutation<any, any>({
			query: (params) => {
				return {
					url: API_ENDPOINTS.UPDATE_CONFIG(params.id),
					method: API_METHODS.PATCH,
					body: params,
				}
			},
			transformErrorResponse(baseQueryReturnValue) {
				handleAuthError(baseQueryReturnValue)
				return baseQueryReturnValue
			},
		}),
		getProjectTrades: builder.query<any, any>({
			query: (id) => {
				return {
					url: API_ENDPOINTS.PROJECTS.GET_PROJECT_TRADES(id),
					method: API_METHODS.GET,
				}
			},
			transformErrorResponse(baseQueryReturnValue) {
				handleAuthError(baseQueryReturnValue)
				return baseQueryReturnValue
			},
		}),
		beginSession: builder.mutation<any, any>({
			query: (params) => {
				return {
					url: API_ENDPOINTS.SESSION.BEGIN_SESSION,
					method: API_METHODS.POST,
					body: params,
				}
			},
			invalidatesTags: [projectApiTags.BEGIN_SESSION],
			transformErrorResponse(baseQueryReturnValue) {
				handleAuthError(baseQueryReturnValue)
				return baseQueryReturnValue
			},
		}),
		endSession: builder.mutation<any, any>({
			query: (id) => {
				return {
					url: API_ENDPOINTS.SESSION.END_SESSION(id),
					method: API_METHODS.PATCH,
				}
			},
			invalidatesTags: [projectApiTags.END_SESSION],
			transformErrorResponse(baseQueryReturnValue) {
				handleAuthError(baseQueryReturnValue)
				return baseQueryReturnValue
			},
		}),
		getSessionId: builder.query<any, any>({
			query: (id) => {
				return {
					url: API_ENDPOINTS.PROJECTS.GET_SESSION_ID(id),
					method: API_METHODS.GET,
				}
			},
			providesTags: [projectApiTags.END_SESSION, projectApiTags.BEGIN_SESSION],
			transformErrorResponse(baseQueryReturnValue) {
				handleAuthError(baseQueryReturnValue)
				return baseQueryReturnValue
			},
		}),
		getSessionById: builder.query<any, any>({
			query: (id) => {
				return {
					url: API_ENDPOINTS.SESSION.GET_SESSION(id),
					method: API_METHODS.GET,
				}
			},
			transformErrorResponse(baseQueryReturnValue) {
				handleAuthError(baseQueryReturnValue)
				return baseQueryReturnValue
			},
		}),
		updateSwimlaneSettings: builder.mutation<any, any>({
			query: (params) => {
				return {
					url: API_ENDPOINTS.PROJECTS.UPDATE_SWIMLANE_SETTINGS(params.id),
					method: API_METHODS.PATCH,
					body: params,
				}
			},
			transformErrorResponse(baseQueryReturnValue) {
				handleAuthError(baseQueryReturnValue)
				return baseQueryReturnValue
			},
		}),
		getSessionLogs: builder.query<any, any>({
			query: (params) => {
				return {
					url: API_ENDPOINTS.PROJECTS.SESSION_LOGS(params?.project),
					method: API_METHODS.GET,
					params,
				}
			},
			transformErrorResponse(baseQueryReturnValue) {
				handleAuthError(baseQueryReturnValue)
				return baseQueryReturnValue
			},
		}),
		getSessionDetails: builder.query<any, any>({
			query: (params) => {
				return {
					url: API_ENDPOINTS.PROJECTS.SESSION_DETAILS(params?.id),
					method: API_METHODS.GET,
					params,
				}
			},
			transformErrorResponse(baseQueryReturnValue) {
				handleAuthError(baseQueryReturnValue)
				return baseQueryReturnValue
			},
		}),
		updateSwimlaneState: builder.mutation<any, UpdateSwimlanePayload>({
			query: (params: UpdateSwimlanePayload) => {
				return {
					url: API_ENDPOINTS.PROJECTS.UPDATE_SWIMLANE_STATE(params.id),
					method: API_METHODS.PATCH,
					body: params,
				}
			},
			transformErrorResponse(baseQueryReturnValue) {
				handleAuthError(baseQueryReturnValue)
				return baseQueryReturnValue
			},
		}),
		getSwimlaneStateByProjectId: builder.query<
			SWIMLANE_STATE_RESPONSE,
			{ project: string }
		>({
			query: (params) => {
				return {
					url: API_ENDPOINTS.PROJECTS.GET_SWIMLANE_STATE(params.project),
					method: API_METHODS.GET,
				}
			},
			transformErrorResponse(baseQueryReturnValue) {
				handleAuthError(baseQueryReturnValue)
				return baseQueryReturnValue
			},
		}),
		getProjectCalendar: builder.query<any, string>({
			query: (id) => {
				return {
					url: API_ENDPOINTS.PROJECTS.PROJECT_CALENDAR(id),
					method: API_METHODS.GET,
				}
			},
			transformErrorResponse(baseQueryReturnValue) {
				handleAuthError(baseQueryReturnValue)
				return baseQueryReturnValue
			},
		}),
	}),
})

export const {
	useGetAllClientsQuery,
	useCreateProjectMutation,
	useUpdateProjectMutation,
	useUpdateProjectCalendarMutation,
	useGetAllProjectsQuery,
	useGetProjectDetailsQuery,
	useGetHolidayCalendarDetailsQuery,
	useGetAvailableUsersToAddForProjectQuery,
	useAddMemberToProjectMutation,
	useLazyGetAllProjectsQuery,
	useUpdateUserRoleForProjectMutation,
	useGetAllProjectsForUserQuery,
	useSendFeedbackMutation,
	useGetAllFeedbacksQuery,
	useGetFeedbackByIdQuery,
	useReplyToFeedbackMutation,
	useResolvedFeedbackMutation,
	useGetAllWbsQuery,
	useGetAWbsQuery,
	useCreateActivityMutation,
	useGetAllActivitiesQuery,
	useLazyGetAllActivitiesQuery,
	useGetProjectMembersQuery,
	useGetActivityByIdQuery,
	useLazyGetActivityByIdQuery,
	useUpdateActivityMutation,
	useCreateSubActivityMutation,
	useGetAllSubActivitiesByActivityQuery,
	useLazyGetSubActivityQuery,
	useLazyGetAllSubActivitiesListQuery,
	useUpdateSubActivityMutation,
	useLazyGetSwimlanesListByActivityCodeQuery,
	useLazyGetSwimlanesListByWbsQuery,
	useGetAllConfigurationsQuery,
	useUpdateConfigurationMutation,
	useGetProjectTradesQuery,
	useBeginSessionMutation,
	useEndSessionMutation,
	useGetSwimlaneBasedActivitiesQuery,
	useGetSessionIdQuery,
	useLazyGetSessionByIdQuery,
	useUpdateSwimlaneSettingsMutation,
	useGetSessionLogsQuery,
	useGetSessionDetailsQuery,
	useUpdateSwimlaneStateMutation,
	useGetSwimlaneStateByProjectIdQuery,
	useLazyGetProjectCalendarQuery,
} = ProjectAPIs
