import { Box, Grid2, List, Typography, useTheme } from "@mui/material"
import {
	useLazyGetSwimlanesListByActivityCodeQuery,
	useLazyGetSwimlanesListByWbsQuery,
	useUpdateSwimlaneStateMutation,
} from "../../api/network/projectApiService"
import { useCallback, useEffect, useState } from "react"
import SwimlaneItem from "./SwimlaneItem"
import AppLoader from "../../components/AppLoader/AppLoader"
import { useDispatch } from "react-redux"
import { updateSwimlaneNav } from "../../api/slices/commonSlice"
import { SwimlaneNavState, SwimlaneWBSListItem } from "../../api/types/Project"
import { AppColors } from "../../theme"
import { useSelector } from "react-redux"
import { RootReduxState } from "../../api/store/store"

interface SwimlaneListsProps {
	projectId: number | null
	swimlaneWbsListShowStatus: boolean
	swimlaneActivityListShowStatus: boolean
	clickable: boolean
	viewerWbsCode?: string | null
	viewerActivityCode?: string | null
}

export default function SwimlaneLists(props: SwimlaneListsProps) {
	const {
		projectId,
		swimlaneWbsListShowStatus,
		swimlaneActivityListShowStatus,
		clickable,
		viewerWbsCode,
		viewerActivityCode,
	} = props
	const [swimlaneActivityList, setSwimlaneActivityList] = useState<any[]>([])
	const [wbsSwimlaneList, setWbsSwimlaneList] = useState<SwimlaneWBSListItem[]>(
		[],
	)
	const [selectedWbsCode, setSelectedWbsCode] = useState<string | null>(
		viewerWbsCode ?? null,
	)
	const [selectedActivityId, setSelectedActivityId] = useState<string | null>(
		viewerActivityCode ?? null,
	)
	const swimlaneNavState: SwimlaneNavState = useSelector(
		(state: RootReduxState) => state.commonSliceData,
	)
	const dispatch = useDispatch()

	const [
		getSwimlanesByActivity,
		{ data: activityCodeData, isFetching: isFetchingActivity },
	] = useLazyGetSwimlanesListByActivityCodeQuery()
	const [getSwimlanesByWBS, { data: wbsData, isFetching: isFetchingWBS }] =
		useLazyGetSwimlanesListByWbsQuery()

	const theme = useTheme()

	const handleSelectWbsSwimlane = (id: string | null) => {
		dispatch(
			updateSwimlaneNav({
				selected_wbs: id,
				navType: "wbs",
				projectId: projectId,
				selected_activity_code: swimlaneNavState?.selected_activity_code,
			}),
		)
	}

	const handleSelectActivitySwimlane = (activityCodeItem: any | null) => {
		dispatch(
			updateSwimlaneNav({
				selected_wbs: swimlaneNavState?.selected_wbs,
				navType: "activity",
				projectId: projectId,
				selected_activity_code: activityCodeItem.id,
			}),
		)
	}

	useEffect(() => {
		if (props.viewerWbsCode) {
			setSelectedWbsCode(props.viewerWbsCode)
			handleSelectWbsSwimlane(props.viewerWbsCode)
		}
	}, [props.viewerWbsCode])

	useEffect(() => {
		if (props.viewerActivityCode) {
			setSelectedActivityId(props.viewerActivityCode)
			handleSelectActivitySwimlane(props.viewerActivityCode)
		}
	}, [props.viewerActivityCode])

	useEffect(() => {
		if (activityCodeData?.results) {
			setSwimlaneActivityList(activityCodeData.results)
			if (
				!selectedActivityId &&
				activityCodeData.results.length > 0 &&
				!swimlaneWbsListShowStatus
			) {
				setSelectedActivityId(activityCodeData.results[0]?.actv_code_id)
				handleSelectActivitySwimlane(activityCodeData.results[0])
			}
		}
	}, [
		activityCodeData,
		handleSelectActivitySwimlane,
		swimlaneWbsListShowStatus,
	])

	useEffect(() => {
		if (wbsData?.results) {
			setWbsSwimlaneList(wbsData.results[0]?.children)
			if (
				!selectedWbsCode &&
				wbsData.results.length > 0 &&
				wbsData.results[0]?.children[0]?.children.length > 0
			) {
				setSelectedWbsCode(
					wbsData.results[0]?.children[0]?.children[0]?.id?.toString(),
				)
				handleSelectWbsSwimlane(
					wbsData.results[0]?.children[0]?.children[0]?.id?.toString(),
				)
			}
		}
	}, [handleSelectWbsSwimlane, wbsData])

	useEffect(() => {
		if (swimlaneWbsListShowStatus) {
			getSwimlanesByWBS({
				page: 1,
				page_size: 500,
				project: projectId,
			})
		}
		if (swimlaneActivityListShowStatus && !swimlaneWbsListShowStatus) {
			getSwimlanesByActivity({
				page: 1,
				page_size: 500,
				project: projectId,
			})
		}
	}, [
		swimlaneWbsListShowStatus,
		swimlaneActivityListShowStatus,
		projectId,
		getSwimlanesByWBS,
		getSwimlanesByActivity,
	])

	const handleActivityClick = (item: any) => {
		handleSelectActivitySwimlane(item)
		setSelectedActivityId(item.actv_code_id)
	}

	if (isFetchingActivity || isFetchingWBS) {
		return <AppLoader open />
	}

	return (
		<Box
			sx={{
				bgcolor: theme.palette.primary.main,
				display: "flex",
				flexDirection: "column",
			}}
		>
			{swimlaneActivityListShowStatus &&
				!swimlaneWbsListShowStatus &&
				swimlaneActivityList?.map((swimlane) => (
					<Grid2
						key={swimlane?.id}
						display={"flex"}
						maxHeight={"60px"}
						justifyContent={"space-between"}
						gap={2}
						pl={1}
						// onClick={() => setSelectedActivityId(swimlane.actv_code_id)}
						onClick={() => handleActivityClick(swimlane)}
						sx={{
							backgroundColor:
								selectedActivityId === swimlane.actv_code_id
									? AppColors.secondary
									: "transparent",
							cursor: "pointer",
							padding: 1,
							borderRadius: "4px",
						}}
					>
						<Grid2 width={"100%"}>
							<List key={swimlane?.actv_code_id}>
								<Typography
									color={
										selectedActivityId === swimlane.actv_code_id
											? "#fff"
											: "#fff"
									}
								>
									{swimlane.actv_code_name}
								</Typography>
							</List>
						</Grid2>
					</Grid2>
				))}

			{swimlaneWbsListShowStatus &&
				wbsSwimlaneList.map((swimlane) => (
					<SwimlaneItem
						key={swimlane?.id}
						item={swimlane}
						selectedWbsCode={selectedWbsCode}
						setSelectedWbsCode={setSelectedWbsCode}
						clickable={clickable}
						isViewer={viewerWbsCode ? true : false}
					/>
				))}
		</Box>
	)
}
