import { Box, Grid2, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { Svgs } from "../../assets/svgs"
import { useGetAllProjectsForUserQuery } from "../../api/network/projectApiService"
import AppLoader from "../../components/AppLoader/AppLoader"
import { UserProjectDetail } from "../../api/types/Project"
import { useDispatch } from "react-redux"
import { updateCurrentProject } from "../../api/slices/UserProjectSlice"
import { useSelector } from "react-redux"
import { RootReduxState } from "../../api/store/store"
import DashboardWelcomeContent from "./DashboardWelcomeContent"
import { useNavigate } from "react-router-dom"
import { routeUrls } from "../../routes/routeUrls"

const UserDashboard = () => {
	const { t } = useTranslation("translation", { keyPrefix: "dashboard" })

	const dispatch = useDispatch()
	const navigate = useNavigate()
	const { currentProject } = useSelector(
		(state: RootReduxState) => state.userProjectData,
	)
	const { data: projectsData, isFetching } = useGetAllProjectsForUserQuery()
	const noOfProjects = projectsData?.results?.length || 0
	const projectList = projectsData?.results || []

	const onProjectDetailClick = (id: number) => () => {
		navigate(routeUrls.projects.viewAndEdit(id.toString()))
	}

	const onSessionButtonClick = (_project: UserProjectDetail) => {
		navigate(
			routeUrls.projects.viewerSwimlane(
				_project.project_id.toString(),
				_project.active_session.toString(),
			),
		)
	}

	const handleSelectProject = (_project: UserProjectDetail) => () => {
		dispatch(
			updateCurrentProject(
				_project.project_id === currentProject?.project_id ? null : _project,
			),
		)
		if (
			_project.role === "Project Superintendent" ||
			_project.role === "Team Member"
		) {
			onProjectDetailClick(_project.project_id ?? 0)()
		}
		if (_project.role === "Viewer") {
			onSessionButtonClick(_project)
		}
	}

	if (isFetching) return <AppLoader open />

	if (noOfProjects === 0 || projectList[0]?.role === "Viewer")
		return <DashboardWelcomeContent />

	return (
		<div className="user-dashboard-container">
			<div className="project-count-section">
				<Typography className="project-count">{noOfProjects}</Typography>
				<Typography className="project-count-text">
					{t("total_project_count")}
				</Typography>
			</div>
			<div className="project-list-section">
				<Grid2 container gap={2}>
					{projectList.map((project) => (
						<Grid2
							key={project.project_id}
							size={3.5}
							className={`project-card ${currentProject?.project_id === project.project_id ? "selected" : ""}`}
							onClick={handleSelectProject(project)}
						>
							<Typography className="project-name">
								{project.project_name}
							</Typography>
							<Box display="flex" gap="6px" mt="5px">
								<Svgs.Role />
								<Typography className="project-role">{project.role}</Typography>
							</Box>
						</Grid2>
					))}
				</Grid2>
			</div>
		</div>
	)
}

export default UserDashboard
