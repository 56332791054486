import { Modal, Typography, Box, Grid2 } from "@mui/material"
import { useGetHolidayCalendarDetailsQuery } from "../../../api/network/projectApiService"
import AppLoader from "../../../components/AppLoader/AppLoader"
import { formatDateWithMonthName } from "../../../utils/dateTime"
import { useTranslation } from "react-i18next"
import { useEffect } from "react"

export default function HolidayCalendarModal(props: any) {
    const { open, onClose, id } = props
    const { data, isLoading, isFetching } = useGetHolidayCalendarDetailsQuery(id.toString())
    const { t } = useTranslation("translation", { keyPrefix: "common.listTable" })

    if (isLoading) {
        return <AppLoader open />
    }

    return (
        <Modal open={open} onClose={onClose}>
            <div className="add-member-modal" style={{ width: "80%", height: "80%" }}>
                <div className="modal-heading">
                    <Box sx={{ padding: "0 16px", width: "100%" }}>
                        <Grid2 container alignItems="center"
                            sx={{ display: "flex", position: "relative" }}>
                            <Grid2 sx={{
                                textAlign: "center", position: "absolute",
                                left: "50%", transform: "translateX(-50%)"
                            }}>
                                <Typography className="title" sx={{ marginTop: "20px" }}>{"Holidays List"}</Typography>
                            </Grid2>
                        </Grid2>
                    </Box>
                </div>
                <div className="holiday-list-container">
                    {!isLoading ? (
                        data?.holidays && data.holidays.length > 0 ? (
                            <ul style={{
                                display: 'grid', marginTop: '20px',
                                gridTemplateColumns: 'repeat(8, 1fr)',
                                gap: '10px', maxHeight: '380px',
                                overflowY: 'auto', boxSizing: 'border-box',
                                listStyleType: 'none',
                            }}>
                                {data.holidays.map((date: any, index: number) => (
                                    <li key={index} style={{
                                        backgroundColor: '#f0f0f0', padding: '10px',
                                        fontSize: '14px', borderRadius: '5px', textAlign: 'center'
                                    }}>
                                        {formatDateWithMonthName(date as string)}
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <Box
                                flex={1}
                                display="flex"
                                flexDirection="column"
                                justifyContent="center"
                                alignItems="center"
                                minHeight="60vh"
                            >
                                <Grid2 className="title">{t("no_data")}</Grid2>
                            </Box>
                        )
                    ) : (null)}
                </div>
            </div>
        </Modal>
    )
}