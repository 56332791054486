import React, { useState, useCallback, useEffect } from "react"
import { TextField, InputAdornment, CircularProgress } from "@mui/material"
import SearchIcon from "@mui/icons-material/Search"

const SearchInput = ({
  value,
  onChange,
  placeholder = "Search here",
  fullWidth = true,
  height = "45px",
  showLoader = false,
  onFocus,
}: {
  value: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  placeholder?: string
  fullWidth?: boolean
  height?: string
  showLoader?: boolean
  onFocus?: () => void;
}) => {
  const [searchTerm, setSearchTerm] = useState(value)

  const timeoutRef = React.useRef<any>(null)

  const debouncedSearch = useCallback(
    (newSearchTerm: string) => {
      // clearing the previous timeout when the function called again
      clearTimeout(timeoutRef.current)
      // updating the current timeout
      timeoutRef.current = setTimeout(() => {
        onChange({
          target: { value: newSearchTerm },
        } as React.ChangeEvent<HTMLInputElement>)
      }, 500)

      return () => {
        clearTimeout(timeoutRef.current)
      }
    },
    [onChange],
  )

  useEffect(() => {
    debouncedSearch(searchTerm)
  }, [searchTerm, debouncedSearch])

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value)
  }

  return (
    <TextField
      variant="outlined"
      value={searchTerm}
      onChange={handleInputChange}
      onFocus={onFocus}
      placeholder={placeholder}
      fullWidth={fullWidth}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {showLoader && (
              <CircularProgress
                sx={{ marginRight: 1 }}
                color="primary"
                size={20}
              />
            )}
            <SearchIcon />
          </InputAdornment>
        ),
        style: { height, background: "white" },
      }}
      sx={{
        "& .MuiOutlinedInput-root": {
          height: height,
          "& input": {
            height: "80%",
          },
        },
      }}
    />
  )
}

export default SearchInput
